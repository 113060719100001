import React from "react";
import { connect, ConnectedProps } from "react-redux";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { FontWeightNames } from "@onnit-js/ui/components/themes/interfaces";
import PageNameEnum from "../../enums/PageNameEnum";
import CartMessage from "./CartMessage";
import GlobalState from "../../interfaces/GlobalState";

interface Props extends ReduxProps, BoxProps {
    pageVisible: PageNameEnum;
    color?: string|null;
    fontWeight?: FontWeightNames;
}

const DEFAULT_COLOR = "grays.5";

const CartMessagesContainer = ({ appMessages, cartMessages, pageVisible, color, fontWeight = "regular", ...rest }: Props) => {
    const messages = appMessages.concat(cartMessages);

    return (
        <Box {...rest}>
            {messages.map((message, index) => (
                message.pages_visible.includes(pageVisible) ? (
                    <CartMessage
                        key={message.code ?? index.toString()}
                        level={message.level}
                        messageHtml={message.message_html}
                        fontWeight={fontWeight}
                        color={color ?? DEFAULT_COLOR}
                    />
                ) : null
            ))}
        </Box>
    );
};

const connector = connect(
    (state: GlobalState) => ({
        appMessages: state.app.messages,
        cartMessages: state.cart?.messages ?? [],
    }),
);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CartMessagesContainer);
