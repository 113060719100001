import BraintreeService from "@onnit-js/ui/services//braintree/BraintreeService";
import Cart from "../interfaces/cart/Cart";
import PaymentGateway from "../interfaces/payment/PaymentGateway";
import TokenizedPaymentMethod from "../interfaces/payment/TokenizedPaymentMethod";
import ValidationError from "../errors/ValidationError";
import ErrorMessageEnum from "../enums/ErrorMessageEnum";
import PaymentGatewayIdEnum from "../enums/payment/PaymentGatewayIdEnum";

export default class PaymentService {
    static validate(cart: Cart, gateway: PaymentGateway, btService: BraintreeService, tokenizedMethodSelected: TokenizedPaymentMethod | null): void {
        const { billing_address } = cart;

        // We should always have a billing address on the cart at this point.
        if (!billing_address) {
            throw new ValidationError(
                "Billing address is not set on the cart.",
                ErrorMessageEnum.PAYMEMT_BILLING_MISSING,
            );
        }

        if (gateway.payment_gateway_id === PaymentGatewayIdEnum.BRAINTREE) {
            if (!btService) {
                throw new ValidationError("btService is not set.", ErrorMessageEnum.GENERIC);
            }

            if (!tokenizedMethodSelected && !btService.isHostedFieldsValid()) {
                throw new ValidationError(
                    "HostedFields is invalid.",
                    ErrorMessageEnum.PAYMENT_CARD_VALIDATION,
                );
            }
        }
    }
}
