import { CartCouponConfig } from "../../interfaces/coupon/CartCouponConfig";
import AbstractCouponEvent from "./AbstractCouponEvent";

export default abstract class AbstractCartCouponEvent extends AbstractCouponEvent {
    readonly cart_uuid: string;

    constructor(config: CartCouponConfig) {
        super(config.code);
        this.cart_uuid = config.cart_uuid;
    }
}
