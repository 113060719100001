import Cart from "../../interfaces/cart/Cart";
import CreateOrderResult from "../../interfaces/order/CreateOrderResult";
import AbstractEvent from "./AbstractEvent";

export default class OrderCreatedEvent extends AbstractEvent {
    static readonly eventName: string = "OrderCreated";

    readonly cart: Cart;

    readonly order: CreateOrderResult;

    constructor(cart: Cart, order: CreateOrderResult) {
        super();
        this.cart = cart;
        this.order = order;
    }

    getName(): string {
        return OrderCreatedEvent.eventName;
    }
}
