import React from "react";
import { Text, TextProps } from "@onnit-js/ui/components/text/Text";

const CartHeading: React.FC<React.PropsWithChildren<TextProps>> = (props) => (
    <Text
        as="h2"
        bg="grays.2"
        color="#grays.4"
        fontWeight="bold"
        fontSize="14px"
        textTransform="uppercase"
        textAlign="center"
        borderRadius="4px"
        p="6px 4px 4px 4px"
        mb={1}
        {...props}
    />
);

export default CartHeading;
