import axios, { AxiosInstance } from "axios";

export default abstract class AbstractClient {
    protected axios: AxiosInstance;

    constructor(axiosClient: AxiosInstance | null = null) {
        this.axios = axiosClient || axios.create({
            baseURL: this.getBaseUrl(),
            timeout: this.getTimeout(),
            withCredentials: this.withCredentials(),
        });
    }

    protected abstract getBaseUrl(): string;

    protected getTimeout(): number {
        return 30 * 1000;
    }

    protected withCredentials(): boolean {
        // Only include credentials where necessary, since it requires a pre-flight request.
        return false;
    }
}
