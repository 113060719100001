import React, { ChangeEvent, RefObject, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import TextField from "../textfield/TextField";
import Icon from "../../icon/Icon";

interface Props {
    inputRef?: RefObject<HTMLInputElement>;
    label?: string;
    value: string;
    error?: string | null;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    bg?: string;
}

const PasswordField: React.FC<React.PropsWithChildren<Props>> = (
    {
        inputRef,
        onChange,
        value,
        label = "Password",
        ...rest
    }: Props) => {
    const [isMasked, setIsMasked] = useState(true);
    const type = isMasked ? "password" : "text";

    // Only show the eye mask icon if a password has been entered.
    const icon = value.length ? (
        <Icon
            color="grays.4"
            icon={type === "password" ? IoMdEyeOff : IoMdEye}
            onClick={() => setIsMasked(!isMasked)}
        />
    ) : null;

    return (
        <TextField
            ref={inputRef}
            type={type}
            name="password"
            value={value}
            onChange={onChange}
            autoComplete="password"
            icon={icon}
            label={label}
            {...rest}
        />
    );
};

export default PasswordField;
