import React, { FC } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Switch, { SwitchSize } from "@onnit-js/ui/components/form/toggle/Switch";
import { Text } from "@onnit-js/ui/components/text";
import CartPromoDiscountConfig from "../../../../../interfaces/cart/CartPromoDiscountConfig";
import CartProductInterface from "../../../../../interfaces/cart/CartProduct";
import { calculator } from "./SubscriptionSwitchBanner";

interface Props {
    cartProduct: CartProductInterface;
    canDisable?: boolean;
    onChange: (interval: number | null) => void;
    promoDiscount?: CartPromoDiscountConfig;
}

const SubscriptionSwitchProduct: FC<Props> = ({ cartProduct, canDisable = true, onChange, promoDiscount }) => {
    const { product, product: { defaultSubscriptionIntervalDays } } = cartProduct;
    const isEnabled = !!cartProduct.subscription_interval;

    const percent = calculator.getDiscountPercent(cartProduct, promoDiscount ? [promoDiscount] : []);
    const salePercent = (1 - (product.price / product.msrp)) * 100;
    const isVisible = percent
        && defaultSubscriptionIntervalDays !== null
        && cartProduct.free_gift_eligibility_rule_id === null
        && (!isEnabled || canDisable);

    return isVisible ? (
        <Box display="flex" flexDirection="row" alignItems="center" mt={1} mb={1}>
            <Switch
                enabled={isEnabled}
                onChange={() => onChange(isEnabled ? null : defaultSubscriptionIntervalDays)}
                size={SwitchSize.SMALL}
            />
            <Text as="p" textTransform="uppercase" fontSize={1} ml={1} color="earthGreens.6" fontWeight="bold">
                {/* Add "off Rebills" if the sale price is discounted more than the subscription discount. */}
                Autoship & Save {percent}%{salePercent > percent && " off Rebills"}
            </Text>
        </Box>
    ) : null;
};

export default SubscriptionSwitchProduct;
