import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@onnit-js/ui/components/box/Box";
import Coupon from "../../../interfaces/coupon/Coupon";
import CouponBubble from "./CouponBubble";
import ThunkDispatch from "../../../interfaces/ThunkDispatch";
import Cart from "../../../interfaces/cart/Cart";
import { removeCoupon } from "../../../slices/cartSlice";
import { setIsLoading } from "../../../slices/appSlice";
import RouteEnum from "../../../enums/RouteEnum";

interface Props {
    coupons: Coupon[];
    cartUuid: string;
    canRemoveCoupons: boolean;
    // --- [ Redux injected ] ---
    setIsLoading: (isLoading: boolean) => void;
    removeCoupon: (cartUuid: string, coupon: Coupon) => Promise<Cart>;
}

class CouponBubbleList extends Component<Props> {
    static defaultProps = {
        canRemoveCoupons: true,
    };

    private onRemove = async (coupon: Coupon): Promise<void> => {
        try {
            this.props.setIsLoading(true);
            const cart = await this.props.removeCoupon(this.props.cartUuid, coupon);
            const { pathname } = window.location;
            const paymentPath = `/cart${RouteEnum.PAYMENT}`;

            // Coupon might have modified selected shipping quote(s), like offering free shipping.
            if (pathname === paymentPath && !cart.has_shipping_quotes_selected_for_all_vendors) {
                window.location.pathname = `/cart${RouteEnum.SHIPPING_SPEED}`;
            }
        } finally {
            this.props.setIsLoading(false);
        }
    };

    render() {
        const { coupons, canRemoveCoupons } = this.props;

        return coupons.length ? (
            <Box display="flex" flexDirection="column" alignItems="flex-end" my={1} px={5}>
                {coupons.map((coupon) => {
                    // To reduce confusion, don't let a user remove an auto-applied coupon.
                    const canRemove = canRemoveCoupons && !coupon.is_auto_applied;

                    return (
                        <CouponBubble
                            key={coupon.coupon_id}
                            coupon={coupon}
                            canRemove={canRemove}
                            onRemove={() => this.onRemove(coupon)}
                        />
                    );
                })}
            </Box>
        ) : null;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    setIsLoading: (isLoading: boolean) => dispatch(setIsLoading(isLoading)),
    removeCoupon: (cartUuid: string, coupon: Coupon) => dispatch(removeCoupon(cartUuid, coupon)),
});

export default connect(
    null,
    mapDispatchToProps,
)(CouponBubbleList);
