import { CartProducts } from "../../interfaces/cart/CartProduct";
import Cart from "../../interfaces/cart/Cart";
import AbstractEvent from "./AbstractEvent";

export default class PostUpsellProductsAddedEvent extends AbstractEvent {
    static readonly eventName: string = "PostUpsellProductsAdded";

    readonly cartProductsAdded: CartProducts;

    readonly cart: Cart;

    // The list where the product was added. Used in downstream analytics platforms.
    readonly listName?: string;

    constructor(cartProductsAdded: CartProducts, cart: Cart, listName?: string) {
        super();
        this.cartProductsAdded = cartProductsAdded;
        this.cart = cart;
        this.listName = listName;
    }

    getName(): string {
        return PostUpsellProductsAddedEvent.eventName;
    }
}
