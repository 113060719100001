import React, { useRef } from "react";
import { Transition } from "react-transition-group";
import { Box, BoxProps } from "../box/Box";

interface Props extends BoxProps {
    percent: number;
    barColor?: string;
    barBgColor?: string;
    animateOnMount?: boolean;
}

const duration = 400;

const defaultStyle = {
    transition: `width ${duration}ms cubic-bezier(0, 0, 0,1)`,
    width: "0%",
};

const ProgressBar = ({
                         percent,
                         barColor = "sunwashGolds.4",
                         barBgColor = "sunwashGolds.1",
                         height = "6px",
                         animateOnMount = true,
                         ...rest
                     }: Props) => {
    const nodeRef = useRef(null);
    const transitionStyles: any = {
        entering: { width: `0%` },
        entered: { width: `${percent}%` },
        exiting: { width: "0%" },
        exited: { width: "0%" },
    };

    return (
        <Box
            width="100%"
            height={height}
            bg={barBgColor}
            overflow="hidden"
            {...rest}
        >
            <Transition nodeRef={nodeRef} in appear={animateOnMount} timeout={duration}>
                {(state) => (
                    <Box
                        ref={nodeRef}
                        bg={barColor}
                        height={height}
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    />
                )}
            </Transition>
        </Box>
    );
};

export default ProgressBar;
