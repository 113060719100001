import { AxiosError } from "axios";

export default class HttpUtil {
    static logErroneousRequest(errorMessage: string, error: AxiosError): void {
        if (!error.response) {
            console.error(errorMessage, `Error: ${error.message}`);
            return;
        }
        console.error(errorMessage, error.response);
    }
}
