import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import CartProductMutable from "./CartProductMutable";
import Cart from "../../../../interfaces/cart/Cart";
import { addProduct, updateProduct } from "../../../../slices/cartSlice";
import { addAppMessage, clearAppMessages, setIsLoading } from "../../../../slices/appSlice";
import ErrorMessageEnum from "../../../../enums/ErrorMessageEnum";
import PageNameEnum from "../../../../enums/PageNameEnum";
import CartProductUtil from "../../../../utils/CartProductUtil";
import SubscriptionSwitchBanner from "./subscription/SubscriptionSwitchBanner";

interface Props extends ReduxProps {
    cart: Cart;
}

class CartProductListMutable extends Component<Props> {
    private setError = (error: string) => {
        this.props.addAppMessage({ message: error, pagesVisible: [PageNameEnum.CART] });
    };

    private clearError = () => {
        this.props.clearAppMessages();
    };

    private async updateProduct(productId: number, quantity: number, interval: number | null): Promise<void> {
        try {
            this.clearError();
            this.props.setIsLoading(true);

            await this.props.updateProduct({
                product_id: productId,
                quantity,
                subscription_interval: interval,
                product_stock_priority_token: null,
            }, this.props.cart.cart_uuid);
        } finally {
            this.props.setIsLoading(false);
        }
    }

    // ------------------------- [ Event Handlers ] -------------------------

    private onRemove = (productId: number): void => {
        // Setting quantity to 0 removes the product.
        this.updateProduct(productId, 0, null).catch((error) => {
            console.error("Failed to remove cart product.", error);
            this.setError(error?.response?.data?.error_message ?? ErrorMessageEnum.GENERIC);
        });
    };

    private onChange = (productId: number, quantity: number, interval: number | null): void => {
        this.updateProduct(productId, quantity, interval).catch((error) => {
            console.error("Failed to update cart product.", error);
            this.setError(error?.response?.data?.error_message ?? ErrorMessageEnum.GENERIC);
        });
    };

    // ------------------------- [ Lifecycle Methods ] -------------------------

    render() {
        return (
            <>
                <SubscriptionSwitchBanner cart={this.props.cart} canDisable />
                {this.props.cart.products.map((cartProduct) => (
                    <CartProductMutable
                        key={cartProduct.product.id}
                        cart={this.props.cart}
                        cartProduct={cartProduct}
                        onQuantityChange={(newQuantity: number) => {
                            this.onChange(
                                cartProduct.product.id,
                                newQuantity,
                                cartProduct.subscription_interval,
                            );
                        }}
                        onSubIntervalChange={(newInterval: number | null) => {
                            this.onChange(
                                cartProduct.product.id,
                                cartProduct.quantity,
                                newInterval,
                            );
                        }}
                        onRemove={() => this.onRemove(cartProduct.product.id)}
                        promoDiscount={CartProductUtil.getPromoDiscount(this.props.cart, cartProduct)}
                    />
                ))}
            </>
        );
    }
}

const connector = connect(
    null,
    {
        setIsLoading,
        addProduct,
        updateProduct,
        addAppMessage,
        clearAppMessages,
    },
);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CartProductListMutable);
