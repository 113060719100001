import { AxiosResponse } from "axios";
import AbstractCartClient from "./AbstractCartClient";
import Cart from "../../interfaces/cart/Cart";
import CartProductConfig, { CartProductConfigs } from "../../interfaces/cart/CartProductConfig";
import { CartUpsellConfig } from "../../interfaces/cart/CartUpsell";

interface AddProductsCommand {
    productConfigs: CartProductConfigs;
    cartUuid?: string | null; // Leave empty to create a NEW cart.
    // Token retrieved from the backend that is used for creating a new cart
    // based on an existing order. Used to create post-upsell carts.
    cartInitializer?: string | null;
    doCalculateTax?: boolean;
    trackUri: string;
    clientCountryCode: string | null | undefined;
}

export default class CartProductClient extends AbstractCartClient {
    async addProducts(command: AddProductsCommand): Promise<AxiosResponse<Cart>> {
        const url = command.cartUuid ? `${command.cartUuid}/products` : "";
        const payload: { products: CartProductConfig[]; cart_initializer?: string } = {
            products: command.productConfigs,
        };
        if (command.cartInitializer) {
            payload.cart_initializer = command.cartInitializer;
        }

        return this.axios.post(
            url,
            payload,
            {
                // Include authentication token cookie if present, so the API can associate the cart to the customer.
                withCredentials: true,
                params: {
                    do_calculate_tax: String(command.doCalculateTax ?? false),
                    do_validate: "true",
                    do_remove_erroneous_products: "true",
                    add_to_cart_track_uri: command.trackUri,
                    client_country_code: command.clientCountryCode,
                },
            },
        );
    }

    async updateProduct(productConfig: CartProductConfig, cartUuid: string, doCalculateTax = false): Promise<AxiosResponse<Cart>> {
        const url = `${cartUuid}/products/${productConfig.product_id}`;

        return this.axios.put(
            url,
            {
                quantity: productConfig.quantity,
                subscription_interval: productConfig.subscription_interval || null,
            },
            {
                // Include authentication token cookie if present, so the API can associate the cart to the customer.
                withCredentials: true,
                params: {
                    do_calculate_tax: String(doCalculateTax),
                    do_validate: "true",
                    do_remove_erroneous_products: "true",
                },
            },
        );
    }

    async partiallyUpdateProducts(productConfigs: CartProductConfig[], cartUuid: string, doCalculateTax = false): Promise<AxiosResponse<Cart>> {
        const url = `${cartUuid}/products`;

        return this.axios.patch(url, { products: productConfigs }, {
            // Include authentication token cookie if present, so the API can associate the cart to the customer.
            withCredentials: true,
            params: {
                do_calculate_tax: String(doCalculateTax),
                do_validate: "true",
                do_remove_erroneous_products: "true",
            },
        });
    }

    async getUpsells(cartUuid: string): Promise<AxiosResponse<CartUpsellConfig>> {
        const url = `${cartUuid}/upsells`;

        return this.axios.get(url);
    }
}
