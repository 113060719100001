import PaymentMethodTypeEnum from "@onnit-js/ui/@types/enums/payment-method/PaymentMethodTypeEnum";
import Cart from "../interfaces/cart/Cart";
import CreateOrderConfig, { CreateOrderConfigPaymentMethod } from "../interfaces/order/CreateOrderConfig";
import PaymentGatewayIdEnum from "../enums/payment/PaymentGatewayIdEnum";
import PaymentState from "../interfaces/payment/PaymentState";

export default class CreateOrderConfigFactory {
    static isSaveForced = (cart: Cart | null): boolean => {
        if (!cart) {
            console.error(
                "Cannot determine if payment method should be forcibly saved because cart is unexpectedly null.",
            );
            return false;
        }

        return cart.will_create_subscription;
    };

    static make = (cart: Cart | null, paymentState: PaymentState): CreateOrderConfig => {
        const tokenizedSelected = paymentState.tokenizedMethodSelected;

        if (!cart || !tokenizedSelected) {
            throw new Error("Failed to make CreateOrderConfig because cart and/or tokenizedMethodSelected is unexpectedly null.");
        }

        // Safeguard to cover the case where Apple Pay is used, but it results in a free cart.
        // This is b/c the payment gateway is never refetched since the Payment Page is skipped.
        const gatewayId = cart.totals.grand <= 0 ? PaymentGatewayIdEnum.FREE : tokenizedSelected.payment_gateway_id;
        const config: CreateOrderConfig = {
            amount: cart.totals.grand,
            payment_method: {
                payment_gateway_id: gatewayId,
                parameters: {},
            },
            preferences: cart.preferences,
        };

        if (gatewayId === PaymentGatewayIdEnum.BRAINTREE) {
            const canSave = !!(tokenizedSelected.instrument_type !== PaymentMethodTypeEnum.PAYPAL
                || tokenizedSelected.paypal_can_save);
            const params: CreateOrderConfig["payment_method"]["parameters"] = {
                can_save: canSave,
                // If save is forced, but the method cannot be saved, the backend will return an error that we would intentionally want to fail.
                do_save: CreateOrderConfigFactory.isSaveForced(cart) || !!(canSave && paymentState.do_save),
                device_data: paymentState.device_data,
            };

            if (tokenizedSelected.nonce) {
                params.nonce = tokenizedSelected.nonce;
            } else if (tokenizedSelected.method_token) {
                params.method_token = tokenizedSelected.method_token;
            }

            config.payment_method.parameters = params;
        }

        return config;
    };

    static makePostUpsellOrderConfig = (
        postUpsellCart: Cart,
        postUpsellPaymentMethod: CreateOrderConfigPaymentMethod,
        originalOrderCart: Cart,
        originalOrderPayment: PaymentState,
    ): CreateOrderConfig => ({
        amount: postUpsellCart.totals.grand,
        payment_method: {
            payment_gateway_id: postUpsellPaymentMethod.payment_gateway_id,
            parameters: {
                ...postUpsellPaymentMethod.parameters,
                do_save: false,
                device_data: originalOrderPayment.device_data,
            },
        },
        preferences: {},
    });
}
