import React, { ChangeEvent, FormEvent, useEffect } from "react";
import TextField from "@onnit-js/ui/components/form/textfield/TextField";
import Box from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import { FaChevronRight } from "react-icons/fa";
import Icon from "@onnit-js/ui/components/icon/Icon";

interface Props {
    value: string;
    error: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (event: FormEvent) => void;
}

const CouponInput: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const ref = React.createRef<HTMLInputElement>();

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, [ref]);

    return (
        <form onSubmit={props.onSubmit}>
            <Box display="flex" alignItems="flex-start">
                <TextField
                    ref={ref}
                    placeholder="Coupon or gift card"
                    value={props.value}
                    onChange={props.onChange}
                    maxLength={50}
                    flex="1"
                    my={0}
                    mr={2}
                    error={props.error || null}
                />
                <Button type="submit" size="medium" aria-label="Submit">
                    <Box p="10px" style={{ overflow: "hidden" }} position="relative">
                        <Icon
                            color="white"
                            icon={FaChevronRight}
                            style={{ position: "absolute", transform: "translate(-9px, -8px)" }}
                        />
                    </Box>
                </Button>
            </Box>
        </form>
    );
};

export default CouponInput;
