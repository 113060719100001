import pickBy from "lodash/pickBy";
import CartProduct, { CartProducts } from "../../../interfaces/cart/CartProduct";
import Cart from "../../../interfaces/cart/Cart";
import CreateOrderResult from "../../../interfaces/order/CreateOrderResult";
import RouteEnum from "../../../enums/RouteEnum";
import { EnhancedEcommerceEventNameEnum, StepNumberEnum, UserSessionPayload } from "./types";
import EnhancedEcommercePayloadFactory from "./EnhancedEcommercePayloadFactory";
import { ImpressionProducts } from "../../../events/events/ProductImpressionEvent";

// !!!!! These MUST match the checkout funnel step labels in Google Analytics !!!!!
// See: https://developers.google.com/tag-manager/enhanced-ecommerce#checkoutfunnel
const ROUTE_TO_STEP_NUMBER: Map<RouteEnum, StepNumberEnum> = new Map([
    [RouteEnum.SHIPPING_ADDRESS, StepNumberEnum.ONE],
    [RouteEnum.SHIPPING_SPEED, StepNumberEnum.TWO],
    [RouteEnum.PAYMENT, StepNumberEnum.THREE],
    [RouteEnum.UPSELLS, StepNumberEnum.FOUR],
    [RouteEnum.REVIEW, StepNumberEnum.FIVE],
    [RouteEnum.EXCLUSIVE_OFFERS, StepNumberEnum.SEVEN], // Added as step 7 to preserve historical data.
    [RouteEnum.SUCCESS, StepNumberEnum.SIX],
]);

export default class GoogleTagManagerService {
    constructor() {
        // Make sure this is set in case Google Tag Manager isn't initialized at this point.
        window.dataLayer = window.dataLayer || [];
    }

    getCheckoutStepNumber(route: RouteEnum): StepNumberEnum | undefined {
        return ROUTE_TO_STEP_NUMBER.get(route);
    }

    // ----------[ Tracking Methods ]----------

    trackUserSession(cart: Cart) {
        const { customer } = cart;
        let payload: UserSessionPayload = {
            event: "userSessionSet",
            customerID: customer?.customer_id,
            email: cart.customer_email,
            emailHash: cart.customer_email_hash,
            firstName: customer?.first_name ?? cart.shipping_address?.first_name,
            lastName: customer?.last_name ?? cart.shipping_address?.last_name,
        };

        // Remove falsy values.
        payload = pickBy(payload, (value: any) => !!value);

        if (Object.keys(payload).length) {
            this.pushPayload(payload);
            console.debug("Google Tag Manager: Pushed user session payload.", payload);
        }
    }

    trackProductImpression(products: ImpressionProducts, listName: string) {
        const payload = EnhancedEcommercePayloadFactory.makeProductImpression(products, listName);

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed impression payload.", payload);
    }

    trackCartViewed(cart: Cart) {
        const payload = EnhancedEcommercePayloadFactory.makeCartViewed(cart.customer_email, cart.customer_email_hash, cart.products);

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed cart viewed payload.", payload);
    }

    trackCouponEntered(coupon: string) {
        const payload = EnhancedEcommercePayloadFactory.makeCouponEntered(
            coupon,
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed coupon entered payload.", payload);
    }

    trackCouponApplied(coupon: string) {
        const payload = EnhancedEcommercePayloadFactory.makeCouponApplied(
            coupon,
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed coupon applied payload.", payload);
    }

    trackCouponDenied(coupon: string) {
        const payload = EnhancedEcommercePayloadFactory.makeCouponDenied(
            coupon,
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed coupon denied payload.", payload);
    }

    trackCouponRemoved(coupon: string) {
        const payload = EnhancedEcommercePayloadFactory.makeCouponRemoved(
            coupon,
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed coupon removed payload.", payload);
    }

    trackProductsAdded(cart: Cart, addedProducts: CartProducts, listName?: string) {
        const payload = EnhancedEcommercePayloadFactory.makeProductsAdded(
            cart.customer_email,
            cart.customer_email_hash,
            addedProducts,
            cart.products,
            EnhancedEcommerceEventNameEnum.PRODUCT_ADD,
            listName,
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed products added payload.", payload);
    }

    trackProductRemoved(cart: Cart, removedProduct: CartProduct) {
        const payload = EnhancedEcommercePayloadFactory.makeProductsRemoved(
            cart.customer_email,
            cart.customer_email_hash,
            [removedProduct],
            cart.products,
            EnhancedEcommerceEventNameEnum.PRODUCT_REMOVE
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed product removed payload.", payload);
    }

    trackCheckoutStep(cart: Cart, stepNumber: StepNumberEnum) {
        const payload = EnhancedEcommercePayloadFactory.makeCheckoutStep(cart, stepNumber);

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed checkout step payload.", payload);
    }

    trackCheckoutStepOption(cart: Cart, stepNumber: StepNumberEnum, option: string) {
        const payload = EnhancedEcommercePayloadFactory.makeCheckoutStepOption(cart, stepNumber, option);

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed checkout step option payload.", payload);
    }

    trackPurchase(cart: Cart, order: CreateOrderResult) {
        const payload = EnhancedEcommercePayloadFactory.makePurchase(cart, order, EnhancedEcommerceEventNameEnum.PURCHASE);

        this.pushPayload(payload);

        if (cart.preferences.do_opt_in_email) {
            this.pushPayload({
                event: "newsletter_registered",
                location: "Checkout",
            });
        }

        console.debug("Google Tag Manager: Pushed purchase payload.", payload);
    }

    trackPasswordSet() {
        this.pushPayload({
            event: "customer_password_set",
        });
    }

    trackSurveyCompleted(answer: string, listName?: string) {
        const payload = EnhancedEcommercePayloadFactory.makeSurveyCompletedData(answer, listName);

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed survey completed payload.", payload);
    }

    // ----------[ Post Upsells ]----------
    trackPostUpsellProductsAdded(cart: Cart, addedProducts: CartProducts, listName?: string) {
        const payload = EnhancedEcommercePayloadFactory.makeProductsAdded(
            cart.customer_email,
            cart.customer_email_hash,
            addedProducts,
            cart.products,
            EnhancedEcommerceEventNameEnum.POST_UPSELL_PRODUCT_ADD,
            listName,
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed post upsell products added payload.", payload);
    }

    trackPostUpsellProductsRemoved(cart: Cart, removedProducts: CartProducts) {
        const payload = EnhancedEcommercePayloadFactory.makeProductsRemoved(
            cart.customer_email,
            cart.customer_email_hash,
            removedProducts,
            cart.products,
            EnhancedEcommerceEventNameEnum.POST_UPSELL_PRODUCT_REMOVE
        );

        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed post upsell products removed payload.", payload);
    }

    trackPostUpsellPurchase(cart: Cart, order: CreateOrderResult) {
        const payload = EnhancedEcommercePayloadFactory.makePurchase(cart, order, EnhancedEcommerceEventNameEnum.POST_UPSELL_PURCHASE);
        this.pushPayload(payload);
        console.debug("Google Tag Manager: Pushed post upsell purchase payload.", payload);
    }

    // ----------[ Private Methods ]----------

    private pushPayload(payload: object) {
        // Best practice for single page apps is to clear the ecommerce object on the data layer before pushing.
        // See: https://developers.google.com/tag-manager/enhanced-ecommerce#clear-ecommerce
        if ("ecommerce" in payload) {
            window.dataLayer.push({ ecommerce: null });
        }

        window.dataLayer.push(payload);
    }
}
