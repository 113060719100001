import RouteEnum from "../../enums/RouteEnum";
import Cart from "../../interfaces/cart/Cart";
import AbstractEvent from "./AbstractEvent";

type Option = string | string[] | null | undefined;

export default class PageSubmittedEvent extends AbstractEvent {
    static readonly eventName: string = "PageSubmitted";

    readonly page: RouteEnum;

    readonly cart: Cart | null;

    readonly option: Option;

    constructor(page: RouteEnum, cart: Cart | null, option: Option) {
        super();
        this.page = page;
        this.cart = cart;
        this.option = option;
    }

    getName(): string {
        return PageSubmittedEvent.eventName;
    }
}
