import PromoDiscountConfig from "@onnit-js/ui/@types/interfaces/PromoDiscountConfig";
import SubscriptionDiscountCalculator from "@onnit-js/ui/services/SubscriptionDiscountCalculator";
import Cart from "../interfaces/cart/Cart";
import CartProduct from "../interfaces/cart/CartProduct";

interface DiscountSummary {
    differ: boolean;
    highestPercentage: number | null;
}

export default class CartSubscriptionDiscountCalculator {
    private readonly calculator: SubscriptionDiscountCalculator;

    constructor(calculator: SubscriptionDiscountCalculator) {
        this.calculator = calculator;
    }

    getDiscountPercent({ product }: CartProduct, promos: PromoDiscountConfig[]): number | null {
        if (!product.isEligibleForSubscription) {
            return null;
        }
        return this.calculator.getDiscounts({
            productId: product.id,
            msrp: product.msrp,
            price: product.price,
        }, promos).percent;
    }

    getDiscountSummary(cart: Cart): DiscountSummary {
        const summary: DiscountSummary = {
            differ: false,
            highestPercentage: null,
        };
        cart.products.forEach((cp) => {
            const percent = this.getDiscountPercent(cp, cart.promo_discounts);
            if (percent === null) {
                return;
            }
            if (summary.highestPercentage === null) {
                summary.highestPercentage = percent;
                return;
            }
            if (percent !== summary.highestPercentage) {
                summary.differ = true;
            }
            if (percent > summary.highestPercentage) {
                summary.highestPercentage = percent;
            }
        });
        return summary;
    }
}
