import Cart from "../../interfaces/cart/Cart";
import AbstractEvent from "./AbstractEvent";

export default class CartViewedEvent extends AbstractEvent {
    static readonly eventName: string = "CartViewed";

    readonly cart: Cart;

    constructor(cart: Cart) {
        super();
        this.cart = cart;
    }

    getName(): string {
        return CartViewedEvent.eventName;
    }
}
