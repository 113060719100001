import PaymentMethod from "@onnit-js/ui/@types/interfaces/payment-method/PaymentMethod";
import PaymentGateways from "./PaymentGateways";
import TokenizedPaymentMethod from "./TokenizedPaymentMethod";

export enum PaymentRequestStatus {
    IDLE = "IDLE",
    FETCHING = "FETCHING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export default interface PaymentState {
    gatewaysStatus : PaymentRequestStatus;
    gateways: PaymentGateways;
    customerMethodsStatus: PaymentRequestStatus;
    customerMethods: PaymentMethod[];
    tokenizedMethods: TokenizedPaymentMethod[] | null;
    tokenizedMethodSelected: TokenizedPaymentMethod | null;
    device_data?: string;
    do_save: boolean | null;
    error: string;
}
