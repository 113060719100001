import AbstractEvent from "./AbstractEvent";

export default abstract class AbstractCouponEvent extends AbstractEvent {
    readonly code: string;

    constructor(code: string) {
        super();
        this.code = code;
    }
}
