import React, { useMemo } from "react";
import BrowserSupportService from "@onnit-js/ui/services//BrowserSupportService";
import ErrorBox from "../shared/ErrorBox";

const BrowserSupportMessage = () => {
    // Not sure how much computing this library has to do, so let's only check support on mount.
    const isSupported = useMemo(() => BrowserSupportService.isSupported(), []);

    return isSupported ? null : (
        <ErrorBox
            error="Please upgrade your browser for the best checkout experience."
            textAlign="center"
        />
    );
};

export default BrowserSupportMessage;
