export default class ValidationError extends Error {
    userMessage?: string;

    constructor(message: string, userMessage?: string) {
        super(message);
        console.error(message);

        this.userMessage = userMessage;
    }
}
