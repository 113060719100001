import React from "react";
import { Text } from "@onnit-js/ui/components/text";
import LoyaltyLionPoints from "./LoyaltyLionPoints";

interface Props {
    value: number;
}

export default function RewardPointValueText(props: Props) {
    if (props.value <= 0 || !window.loyaltylion) {
        return null;
    }

    const word = props.value === 1 ? "point" : "points";

    return (
        <Text as="p" color="grays.4" lineHeight={6} fontSize={1} textAlign="center" p={1}>
            You will earn <LoyaltyLionPoints value={props.value} /> Onnit X Rewards {word} for this order.
        </Text>
    );
}
