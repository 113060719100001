import { AxiosResponse } from "axios";
import PaymentMethod from "@onnit-js/ui/@types/interfaces/payment-method/PaymentMethod";
import AbstractClient from "./AbstractClient";
import config from "../config";

export default class PaymentMethodClient extends AbstractClient {
    protected getBaseUrl(): string {
        return config.API_URL;
    }

    protected withCredentials(): boolean {
        return true;
    }

    listMethods(customerId: number, shouldSync = true): Promise<AxiosResponse<PaymentMethod[]>> {
        return this.axios.get("/payment-methods", {
            params: {
                customer_id: customerId,
                should_sync: shouldSync.toString(),
            },
        });
    }
}
