import React, { FC } from "react";
import { Text } from "@onnit-js/ui/components/text";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FiRefreshCw } from "react-icons/fi";
import { MdInfoOutline } from "react-icons/md";
import Tooltip from "@onnit-js/ui/components/tooltip/Tooltip";
import { SubscriptionRebillDetails } from "../../../../../interfaces/cart/CartProduct";

interface Props extends BoxProps {
    details: SubscriptionRebillDetails | null;
}

const SubscriptionDetailsText: FC<Props> = ({ details, ...boxProps }) => (details ? (
    <Box display="flex" alignItems="flex-start" {...boxProps}>
        <Icon icon={FiRefreshCw} size={15} color="grays.5" mr={1} flexShrink={0} />
        <Text display="block" fontSize={1} color="grays.5">
            {details.interval_text}
        </Text>
        <Tooltip
            style={{ zIndex: 99999999 }}
            text={`${details.discount_percentage_text}. Frequency can be easily managed in your Account Dashboard.`}
        >
            <Icon
                icon={MdInfoOutline}
                ml={1}
                role="presentation"
                ariaLabel="Subscription details"
            />
        </Tooltip>
    </Box>
) : null);

export default SubscriptionDetailsText;
