import React from "react";
import styled from "styled-components";
import LogoProgress from "./LogoProgress";
import { Box, BoxProps } from "../box/Box";

const Overlay = styled(Box)<BoxProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    user-select: none;
    z-index: 10000000;
`;

const OverlayProgress = ({ bg = "rgba(255, 255, 255, 0.6)", width = "100%", position = "fixed", ...rest }: BoxProps) => (
    <Overlay
        bg={bg}
        width={width}
        position={position}
        {...rest}
        onClick={(event) => {
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            if (event.nativeEvent && event.nativeEvent.stopImmediatePropagation) {
                event.nativeEvent.stopImmediatePropagation();
            }
        }}
    >
        <LogoProgress showLoader />
    </Overlay>
);

export default OverlayProgress;
