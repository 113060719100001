import React from "react";
import { FreeGiftApparelVariant, FreeGiftApparelVariantSize } from "../../../../../interfaces/cart/FreeGift";
import ProductTypeIdEnum from "../../../../../enums/ProductTypeIdEnum";

interface Props {
    product: FreeGiftApparelVariant | FreeGiftApparelVariantSize;
}

const ApparelOption = ({ product }: Props) => {
    let label = "";

    if (product.typeId === ProductTypeIdEnum.APPAREL_VARIANT) {
        label = product.primaryColor;

        if (product.secondaryColor && product.secondaryColor !== label) {
            label += ` / ${product.secondaryColor}`;
        }
    } else {
        label = product.size;
    }

    if (!product.isInStock) {
        label += " (out of stock)";
    }

    return (
        <option value={product.id.toString()} disabled={!product.isInStock}>{label}</option>
    );
};

export default ApparelOption;
