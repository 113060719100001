import React, { FC, useEffect, useState } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { MdInfoOutline } from "react-icons/md";
import ProgressButton from "./ProgressButton";
import FreeGift, { FreeGifts } from "../../../../../interfaces/cart/FreeGift";
import NumberUtil from "../../../../../utils/NumberUtil";
import FreeGiftAddBox from "./FreeGiftAddBox";
import FreeGiftTypeEnum from "../../../../../enums/FreeGiftTypeEnum";

const getLabel = (
    freeGiftType: string | undefined,
    threshold: number,
    thresholdRemaining: number,
    selectionRequired: boolean,
    hasGiftInCart: boolean,
): string => {
    const isThresholdMet = thresholdRemaining <= 0.0;
    const formatted = NumberUtil.formatMoney(
        Math.ceil(thresholdRemaining), // Use the ceiling for visual purposes, since we don't show cents.
        false,
    );

    if (freeGiftType === FreeGiftTypeEnum.COUPON) {
        if (isThresholdMet) {
            return "Qualified";
        }
    }

    if (hasGiftInCart) {
        return "Gift added";
    }

    if (isThresholdMet) {
        return selectionRequired ? "Select gift" : "Gift added";
    }

    return `${formatted} away`;
};

/**
 * Calculates "percentage of completion" for gifts threshold (e.g. free shipping)
 * Takes into account possibility of "threshold = 0" which may come from coupons with threshold override
 */
const getPercentComplete = (
    threshold: number,
    thresholdRemaining: number,
): number => (threshold > 0 ? 100 - Math.floor((thresholdRemaining / threshold) * 100) : 100);

interface FreeGiftGroupProps{
    name: string;
    description: string | null;
    freeGiftType?: string | undefined;
    threshold?: number;
    thresholdRemaining?: number;
    freeGifts?: FreeGifts;
    onAddClick?: (productId: number) => Promise<void>;
    withBorder?: boolean;
}

const FreeGiftGroup: FC<React.PropsWithChildren<FreeGiftGroupProps>> = ({
    name,
    description,
    freeGiftType,
    threshold = 1,
    thresholdRemaining = 0,
    freeGifts,
    onAddClick,
    withBorder = true,
}) => {
    const hasGiftInCart = freeGifts?.some((gift) => gift.is_in_cart) ?? false;
    const selectionRequired = !!(freeGifts && freeGifts.length > 0);
    const buttonLabel = getLabel(freeGiftType, threshold, thresholdRemaining, selectionRequired, hasGiftInCart);
    const percentComplete = getPercentComplete(threshold, thresholdRemaining);
    const isCouponGroup = freeGifts?.every((gift: FreeGift) => gift.free_gift_type === FreeGiftTypeEnum.COUPON);
    const [isOpen, setOpen] = useState(false);

    // Determine if one gift can be still be added to the cart.
    const canSelectGift = freeGifts?.every((gift) => gift.can_add_to_cart && !gift.is_in_cart);

    useEffect(() => {
        setOpen(!!freeGifts && percentComplete === 100 && !hasGiftInCart);
    }, [freeGifts, percentComplete, hasGiftInCart]);

    const handleClick = () => {
        // If gift group is just a coupon, toggle open state
        if (isCouponGroup) {
            setOpen((open) => !open);
            return;
        }

        // Toggle open for other things like free shipping
        setOpen((open) => !open);
    };

    return (
        <Box px={2} py={3} borderBottom={withBorder ? "1px solid" : ""} borderColor="grays.1">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ cursor: canSelectGift ? "pointer" : "initial" }}
                tabIndex={0}
                onClick={() => handleClick()}
                onKeyDown={(evt) => {
                    if (evt.code === "Enter" || evt.code === "Space") {
                        evt.preventDefault();
                        evt.stopPropagation();
                        handleClick();
                    }
                }}
            >
                <p className="sr-only" role="status" aria-live="polite">
                    {name}{" "}{buttonLabel}
                </p>
                <Text fontWeight="heavy" fontSize={[1, 1, 2]}>
                    {name}
                    {description && (
                        <Icon
                            icon={MdInfoOutline}
                            ml={1}
                            style={{ transform: "translateY(-2px)" }}
                            role="presentation"
                        />
                    )}
                </Text>
                <ProgressButton
                    isOpen={isOpen}
                    label={buttonLabel}
                    percentComplete={percentComplete}
                    hasGiftInCart={hasGiftInCart}
                    selectionRequired={selectionRequired}
                    showIcon
                    doAnimate
                />
            </Box>
            {isOpen && (
                <Box mt={2}>
                    <Text as="p" px={3} fontSize={1} lineHeight={5} color="grays.4">{description}</Text>
                    {freeGifts?.map((gift, index) => (
                        <FreeGiftAddBox
                            key={`${gift.name}_${gift.eligibility_rule_id}`}
                            freeGift={gift}
                            onAddClick={(productId) => {
                                onAddClick?.(productId).then(() => setOpen(false)).catch(() => {
                                    console.log("Failed to add free gift to cart");
                                });
                            }}
                            withBorder={index + 1 < freeGifts.length}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default FreeGiftGroup;
