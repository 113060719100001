import Cart from "../../interfaces/cart/Cart";
import RouteEnum from "../../enums/RouteEnum";
import AbstractEvent from "./AbstractEvent";

export default class PageInitializedEvent extends AbstractEvent {
    static readonly eventName: string = "PageInitialized";

    readonly cart: Cart;

    readonly route: RouteEnum;

    constructor(cart: Cart, route: RouteEnum) {
        super();
        this.cart = cart;
        this.route = route;
    }

    getName(): string {
        return PageInitializedEvent.eventName;
    }
}
