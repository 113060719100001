export default class NumberUtil {
    static formatMoney(value: number, withCents = true): string {
        const formatted: string = withCents
            ? value.toFixed(2)
            : Math.ceil(value).toString();

        return `$${formatted}`;
    }

    static format(value: number): string {
        return value.toLocaleString("en");
    }
}
