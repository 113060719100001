import React, { KeyboardEvent, MouseEvent, useEffect, useState } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import FreeGift, { FreeGiftApparelVariantSize } from "../../../../../interfaces/cart/FreeGift";
import ApparelSelectBoxContainer from "./ApparelSelectBoxContainer";
import ProductUtil from "../../../../../utils/ProductUtil";
import FreeGiftTypeEnum from "../../../../../enums/FreeGiftTypeEnum";

interface Props {
    freeGift: FreeGift;
    withBorder: boolean;
    onAddClick?: (productId: number) => void;
}

enum Status {
    "IS_IN_CART" = "IS_IN_CART",
    "CAN_ADD_TO_CART" = "CAN_ADD_TO_CART",
    "DISABLED" = "DISABLED",
}

const FreeGiftAddBox = ({ freeGift, withBorder, onAddClick }: Props) => {
    const { product, virtual_free_gift } = freeGift;
    const isProductFreeGiftType = freeGift.free_gift_type === FreeGiftTypeEnum.PRODUCT;

    const slug = product?.slug ?? virtual_free_gift?.slug ?? null;
    const authorityImageUrl = product?.authorityImageUrl ?? virtual_free_gift?.authorityImageUrl ?? "";
    const name = product?.name ?? virtual_free_gift?.name ?? "";
    const altText = virtual_free_gift?.authorityImageAltTxt ?? name;

    const productUrl = slug ? ProductUtil.makeProductUrl(slug) : null;
    const requiresSelection = ProductUtil.isApparelParentTypeId(product?.typeId) ?? false;
    // If it doesn't require a selection, there's only product, i.e. nothing to select.
    const [selectedProduct, setSelectedProduct] = useState(requiresSelection ? null : product);
    const [imageUrl, setImageUrl] = useState(authorityImageUrl);
    const [status, setStatus] = useState<Status>(Status.DISABLED);

    useEffect(() => {
        if (freeGift.is_in_cart) {
            setStatus(Status.IS_IN_CART);
        } else if (freeGift.can_add_to_cart && (!requiresSelection || (requiresSelection && selectedProduct))) {
            setStatus(Status.CAN_ADD_TO_CART);
        } else {
            setStatus(Status.DISABLED);
        }
    }, [freeGift, requiresSelection, selectedProduct]);

    const onSelect = (evt: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
        // Ignore clicks on child select and anchor elements.
        if (["SELECT", "A"].includes((evt.target as Element).nodeName)) {
            return;
        }
        evt.preventDefault();
        evt.stopPropagation();

        if (status !== Status.CAN_ADD_TO_CART) {
            return;
        }

        if (!selectedProduct) {
            console.error("Cannot add free gift because selected product was unexpectedly not set.");
            return;
        }

        // Bubble it up.
        onAddClick?.(selectedProduct.id);
    };

    if (isProductFreeGiftType && !product) {
        console.error("Free Gift contains no product associated with it.");
        return null;
    }

    if (!isProductFreeGiftType && !virtual_free_gift) {
        console.error("Free Gift contains no virtual_free_gift associated with it.");
        return null;
    }

    return (
        <Box
            p={3}
            borderBottom={withBorder ? "1px solid" : ""}
            borderColor="grays.1"
            onClick={onSelect}
            onKeyDown={(evt) => {
                if (evt.code === "Enter" || evt.code === "Space") {
                    onSelect(evt);
                }
            }}
            style={{
                cursor: status === Status.CAN_ADD_TO_CART && isProductFreeGiftType
                    ? "pointer"
                    : "initial",
            }}
        >
            <Box display="flex">
                <img
                    src={imageUrl}
                    alt={altText}
                    style={{ width: 60, marginRight: 8 }}
                />
                <Box
                    flex="1"
                    display="flex"
                    flexDirection={requiresSelection ? "column" : "row"}
                    alignItems={requiresSelection ? "initial" : "center"}
                    justifyContent="space-between"
                >
                    <Box>
                        <Text
                            as="p"
                            display="block"
                            fontSize={1}
                            flex="1"
                            px={1}
                        >
                            {name} {productUrl && (
                                <a
                                    href={productUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "#6f6f6f" }}
                                >
                                    Learn more <span className="sr-only">about {name}</span>
                                </a>
                            )}
                        </Text>
                    </Box>
                    {isProductFreeGiftType && (
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            {requiresSelection && (
                                <ApparelSelectBoxContainer
                                    flex="1"
                                    mr={2}
                                    freeGift={freeGift}
                                    setImageUrl={setImageUrl}
                                    selectedSize={selectedProduct ? selectedProduct as FreeGiftApparelVariantSize : null}
                                    setSelectedSize={setSelectedProduct}
                                />
                            )}

                            <Button
                                width="74px"
                                size="small"
                                raised={false}
                                disabled={!freeGift.can_add_to_cart || freeGift.is_in_cart}
                            >
                                {freeGift.is_in_cart ? "Added" : "Add"}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default FreeGiftAddBox;
