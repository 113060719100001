import React from "react";
import { Text } from "@onnit-js/ui/components/text/Text";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { MdClose } from "react-icons/md";
import Coupon from "../../../interfaces/coupon/Coupon";
import Bubble from "../../shared/oui/bubble/Bubble";

interface Props {
    coupon: Coupon;
    canRemove: boolean;
    onRemove: () => void;
}

export default function CouponBubble(props: Props) {
    return (
        <Bubble ml="10px" mb="5px">
            <Text color="grays.5" fontSize="13px" textTransform="uppercase" lineHeight={3} pl={1} tabIndex={-1}>
                <span className="sr-only">Coupon in use: </span>
                {props.coupon.code}
            </Text>
            {props.canRemove && (
                <Icon
                    color="grays.6"
                    size={15}
                    ml={1}
                    mt="-1px"
                    icon={MdClose}
                    onClick={() => props.onRemove && props.onRemove()}
                    ariaLabel="Remove Coupon"
                />
            )}
        </Bubble>
    );
}
