import { AxiosResponse } from "axios";
import qs from "qs";
import AbstractClient from "./AbstractClient";
import LoginConfig from "../interfaces/auth/LoginConfig";
import config from "../config";
import LoginResponse from "../interfaces/auth/LoginResponse";
import LoginBootstrap from "../interfaces/auth/LoginBootstrap";

export default class AuthClient extends AbstractClient {
    protected getBaseUrl(): string {
        return `${config.ALLIT_URL}/cart/cart-api.php`;
    }

    /**
     * Encodes request body data into `application/x-www-form-urlencoded` format.
     */
    private static encodeData(data: object): string {
        return qs.stringify(data);
    }

    async getBootstrap(): Promise<AxiosResponse<LoginBootstrap>> {
        return this.axios.get("?action=getLoginBootstrap");
    }

    async login(config: LoginConfig): Promise<AxiosResponse<LoginResponse>> {
        const payload: { [prop: string]: string } = {
            action: "login",
            email: config.email,
            password: config.password,
            _CSRF_TOKEN: config.csrf._CSRF_TOKEN, // eslint-disable-line no-underscore-dangle
            _CSRF_INDEX: config.csrf._CSRF_INDEX, // eslint-disable-line no-underscore-dangle
        };

        if (config.captchaResponseToken) {
            payload["g-recaptcha-response"] = config.captchaResponseToken;
        }

        const data = AuthClient.encodeData(payload);

        return this.axios.post("", data, {
            validateStatus(status) {
                return (status >= 200 && status < 300)
                    || status === 403;
            },
        });
    }

    protected withCredentials(): boolean {
        // These requests use a PHP session cookie to pass CSRF checks.
        return true;
    }
}
