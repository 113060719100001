import React, { ChangeEvent, Component, FormEvent, RefObject } from "react";
import { connect } from "react-redux";
import TextField from "@onnit-js/ui/components/form/textfield/TextField";
import Button from "@onnit-js/ui/components/button/Button";
import Box from "@onnit-js/ui/components/box/Box";
import PasswordField from "@onnit-js/ui/components/form/passwordfield/PasswordField";
import OverlayProgress from "@onnit-js/ui/components/progress/OverlayProgress";
import GoogleReCaptcha from "./GoogleReCaptcha";
import AuthState from "../../../interfaces/auth/AuthState";
import LoginConfig from "../../../interfaces/auth/LoginConfig";
import LoginResponse from "../../../interfaces/auth/LoginResponse";
import LoginBootstrap from "../../../interfaces/auth/LoginBootstrap";
import GlobalState from "../../../interfaces/GlobalState";
import ThunkDispatch from "../../../interfaces/ThunkDispatch";
import { loadLoginBootstrap, login } from "../../../slices/authSlice";
import { setIsLoading } from "../../../slices/appSlice";
import ErrorBox from "../ErrorBox";
import ErrorMessageEnum from "../../../enums/ErrorMessageEnum";

interface Props {
    initialEmail?: string | null;
    onLoginSuccess: () => void;
    onCancel?: () => void;
    // --- [ Redux injected ] ---
    auth: AuthState;
    setIsLoading: (isLoading: boolean) => void;
    login: (config: LoginConfig) => Promise<LoginResponse>;
    loadLoginBootstrap: () => Promise<LoginBootstrap>;
}

interface State {
    email: string;
    password: string;
    captchaResponseToken: string;
    error: string;
    isSubmitting: boolean;
}

class AccountLoginForm extends Component<Props, State> {
    private captchaId: number | null = null;

    private readonly emailInputRef: RefObject<HTMLInputElement>;

    private readonly passwordInputRef: RefObject<HTMLInputElement>;

    state = {
        email: this.props.initialEmail || "",
        password: "",
        captchaResponseToken: "",
        error: "",
        isSubmitting: false,
    };

    constructor(props: Props) {
        super(props);

        this.emailInputRef = React.createRef();
        this.passwordInputRef = React.createRef();
    }

    private resetCaptcha(): void {
        if (this.captchaId === null) {
            throw new Error("Cannot reset CAPTCHA because captchaId is null.");
        }

        (window as any).grecaptcha.reset(this.captchaId);
    }

    private async login(): Promise<void> {
        const { auth, login } = this.props;

        if (auth.csrf === null) {
            throw new Error("Cannot login because CSRF tokens have not been set.");
        }

        await login({
            email: this.state.email,
            password: this.state.password,
            captchaResponseToken: this.state.captchaResponseToken,
            csrf: auth.csrf,
        });
    }

    // ------------------------- [ State Setters ] -------------------------

    private clearPassword(): void {
        this.setState({
            password: "",
        });
    }

    private setError(error: string): void {
        this.setState({
            error,
        });
    }

    private clearError(): void {
        this.setError("");
    }

    // ------------------------- [ Event Handlers ] -------------------------

    private onChange = (event: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore Due to TypeScript bug with computed property names: https://github.com/Microsoft/TypeScript/issues/13948
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    private onCaptchaRendered = (captchaId: number) => {
        console.debug("reCAPTCHA rendered successfully.", captchaId);

        this.captchaId = captchaId;
    };

    private onCaptchaSuccess = (responseToken: string) => {
        console.debug("Got successful response token from reCAPTCHA.", responseToken);

        this.setState({
            captchaResponseToken: responseToken,
        });
    };

    private onSubmit = async (event: FormEvent): Promise<void> => {
        event.preventDefault();
        if (!this.state.email.length || !this.state.password.length) {
            return;
        }
        const { setIsLoading, onLoginSuccess } = this.props;

        this.clearError();
        setIsLoading(true);
        this.setState({
            isSubmitting: true
        });

        try {
            await this.login();
            setIsLoading(false);
            this.reset();
            onLoginSuccess();
         } catch (error: any) {
            this.clearPassword();
            this.setError(error.message ?? ErrorMessageEnum.GENERIC);
            setIsLoading(false);
            this.reset();
        }
    };

    private reset = () => {
        // Reset the CAPTCHA, if applicable.
        if (this.captchaId !== null) {
            this.resetCaptcha();
        }

        this.setState({
            isSubmitting: false
        });
    };

    // ------------------------- [ Lifecycle Methods ] -------------------------

    componentDidMount() {
        this.props.loadLoginBootstrap()
            .then(() => console.debug("Successfully initialized AccountLoginForm."))
            .catch((error) => {
                console.error("Failed to initialize AccountLoginForm.", error);
                this.setError(ErrorMessageEnum.GENERIC);
            });

        // If the email field has a value, focus the password field.
        if (this.state.email) {
            this.passwordInputRef?.current?.focus();
        } else {
            // Otherwise focus the email field.
            this.emailInputRef?.current?.focus();
        }
    }

    render() {
        const { auth, onCancel } = this.props;

        // The form "method" attribute is only present to hush the LastPass insecure false positive warning.
        return (
            <>
                {this.state.isSubmitting && <OverlayProgress />}
                <form onSubmit={this.onSubmit} method="post" style={{ marginBottom: 24 }}>
                    {this.state.error && (
                    <ErrorBox error={this.state.error} />
                )}
                    <Box mb={5}>
                        <Box display={["block", "block", "flex"]} justifyContent="space-between">
                            <Box flex="0 1 49%">
                                <TextField
                                    ref={this.emailInputRef}
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    label="Email"
                                    autoComplete="username"
                                />
                            </Box>
                            <Box flex="0 1 49%">
                                <PasswordField
                                    inputRef={this.passwordInputRef}
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    label="Password"
                                />
                            </Box>
                        </Box>
                        {auth.isCaptchaRequired && (
                        <GoogleReCaptcha
                            onRendered={this.onCaptchaRendered}
                            onSuccess={this.onCaptchaSuccess}
                        />
                    )}
                    </Box>
                    <Button disabled={this.state.isSubmitting} type="submit" width="100%">
                        Sign in
                    </Button>
                    <Box display="flex" justifyContent="center" mt={3}>
                        {onCancel
                        && (
                            <Button
                                size="small"
                                fill="text"
                                onClick={(event) => {
                                    event.preventDefault();
                                    this.clearError();
                                    onCancel();
                                }}
                            >
                                Cancel
                            </Button>
                        )}
                        <Button el="a" size="small" fill="text" href="/cart/password_forgotten.php" target="_blank">
                            Forgot password?
                        </Button>
                    </Box>
                </form>
            </>
        );
    }
}

const mapStateToProps = (state: GlobalState) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
    setIsLoading: (isLoading: boolean) => dispatch(setIsLoading(isLoading)),
    login: (config: LoginConfig) => dispatch(login(config)),
    loadLoginBootstrap: () => dispatch(loadLoginBootstrap()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountLoginForm);
