import { AxiosResponse } from "axios";
import AbstractCartClient from "./AbstractCartClient";
import GetCartConfig from "../../interfaces/cart/GetCartConfig";
import Cart from "../../interfaces/cart/Cart";
import UpdateCartCustomerResponse from "../../interfaces/cart/UpdateCartCustomerResponse";

export default class CartClient extends AbstractCartClient {
    async getCart(cartUuid: string, config: GetCartConfig): Promise<AxiosResponse<Cart>> {
        return this.axios.get(cartUuid, {
            // Include authentication token cookie if present, so the API can associate the cart to the customer.
            withCredentials: true,
            // Config booleans are correctly casted to strings.
            params: config,
        });
    }

    async updateCustomerEmail(cartUuid: string, email: string): Promise<AxiosResponse<UpdateCartCustomerResponse>> {
        const url = `${cartUuid}/customer`;

        return this.axios.put(url, {
            customer_email: email,
        });
    }
}
