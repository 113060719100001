import styled from "styled-components";
import qs from "qs";
import React from "react";
import config from "../../../config";

interface Props {
    customerId: number;
}

const VerifyButtonLink = styled.a`
    border: none;
    cursor: pointer;
    display: block;
    text-decoration: none;
    width: 190px;
    position: relative;
    margin: 12px auto;
    -webkit-font-smoothing: antialiased;
    
    span {
        border-radius: 4px;
        box-sizing: border-box;
        color: #fff;
        font: 700 12px arial, sans-serif;
        line-height: 34px;
        display: inline-block;
        background: #2d3e51;
        padding: 0 24px 0 54px;
        text-align: center;
        cursor: pointer;
        
        &::before {
            border-right: 1px solid #fff;
            content: '';
            height: 34px;
            position: absolute;
            left: 0;
            width: 34px;
            background: transparent url(https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/idme-badge.png) center no-repeat;
            background-size: 20px 20px;
        }
    }
    
    img {
        border: 0;
        vertical-align: text-top;
        height: 16px;
        margin: -2px 0 0 2px;
    }
`;

const IdMeVerifyButton = ({ customerId }: Props) => {
    const url = `https://groups.id.me/?${qs.stringify({
        scopes: "responder,military,teacher,nurse,medical",
        client_id: config.IDME_CLIENT_ID,
        // Since this is only rendered if we have a customer in state, include the customer ID in the `state` query param.
        // When the flow is successful, ID.me will include the `state` as a query parameter value to the redirect URI endpoint.
        // That endpoint in the API assigns the group to the customer then redirects back to checkout.
        redirect_uri: `${config.API_URL}/idme/postback`,
        response_type: "code",
        state: customerId,
        logo: "https://onnits3.imgix.net/touch-icon.png?w=192",
    })}`;

    return (
        <VerifyButtonLink href={url}>
            <span>Verify with <img src="https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/idme-logo-white.png" alt="ID.me" /></span>
        </VerifyButtonLink>
    );
};

export default IdMeVerifyButton;
