import Product from "../../interfaces/cart/Product";
import { UpsellProduct } from "../../interfaces/cart/CartUpsell";
import AbstractEvent from "./AbstractEvent";

export type ImpressionProducts = Array<Product | UpsellProduct>;

export default class ProductImpressionEvent extends AbstractEvent {
    static readonly eventName: string = "ProductImpression";

    // Products should be ordered in the position that they are shown.
    readonly products: ImpressionProducts;

    readonly listName: string;

    constructor(products: ImpressionProducts, listName: string) {
        super();
        this.products = products;
        this.listName = listName;
    }

    getName(): string {
        return ProductImpressionEvent.eventName;
    }
}
