// String enum for easier debugging.
enum PaymentMethodInstrumentEnum {
    AMEX = "AMEX",
    DISCOVER = "DISCOVER",
    MASTERCARD = "MASTERCARD",
    VISA = "VISA",
    PAYPAL = "PAYPAL",
    FREE = "FREE",
    OTHER = "OTHER",
    APPLE_PAY = "APPLE_PAY",
    JCB = "JCB",
}

export default PaymentMethodInstrumentEnum;
