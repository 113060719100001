import Cookies from "js-cookie";
import { v4 as uuidv4, validate as uuidValidate, version as uuidVersion } from "uuid";
import config from "../config";

export default class CartBrowserStorageService {
    static readonly CART_UUID_KEY = "ONNIT_CART_UUID";

    static readonly ONNIT_VISITOR_UUID_KEY = "ONNIT_VISITOR_UUID";

    static readonly CART_QUANTITY_SUM_KEY = "ONNIT_CART_QUANTITY_SUM";

    static readonly SALE_SOURCE_TRACKING_ID_KEY = "sst";

    static readonly AFFILIATE_TRACKING_CODE_KEY = "ONNIT_AFFILIATE_CODE";

    static readonly FHS_SLUG_KEY = "grm";

    // These are used in allit to determine what to show in the header.
    static readonly FHS_MSG_KEY = "grm_msg";

    static readonly FHS_PERCENT_KEY = "grm_percent";

    static removeCartValues(): void {
        this.removeCartUuid();
        this.removeQuantitySum();
    }

    static removeTrackingValues(): void {
        this.removeSaleSourceTrackingId();
        this.removeAllFhs();
        this.removeAffiliateTrackingCode();
    }

    // ------------------------- [ Cart UUID ] -------------------------
    // Cart UUID is stored in cookies instead of localStorage solely for compatibility with functionality in allit
    // that sets the cart UUID cookie after authenticating if the customer had recent a active one.

    static getCartUuid(): string | undefined {
        // Cookie domain is not needed for get, only set and remove.
        return Cookies.get(this.CART_UUID_KEY);
    }

    static setCartUuid(cartUuid: string): void {
        // Local environments don't use HTTPS.
        // Get the cookie domain from the environment so it's the same set by allit after authenticating.
        Cookies.set(this.CART_UUID_KEY, cartUuid, {
            domain: config.COOKIE_DOMAIN,
            secure: !config.APP_ENV_IS_LOCAL,
            expires: 30, // In days.
        });
    }

    static removeCartUuid(): void {
        const cartUuid = this.getCartUuid();

        if (!cartUuid) {
            return;
        }

        this.remove(this.CART_UUID_KEY, config.COOKIE_DOMAIN);
    }

    // ------------------------- [ Quantity Sum ] -------------------------

    static getQuantitySum(): number | undefined {
        let sum = null;

        try {
            sum = window.localStorage.getItem(this.CART_QUANTITY_SUM_KEY);
        } catch (error: any) {
            console.error("Storage: Failed to get quantity sum in localStorage.", error);
        }

        return sum ? parseInt(sum) : undefined;
    }

    static setQuantitySum(sum: number): void {
        try {
            window.localStorage.setItem(this.CART_QUANTITY_SUM_KEY, sum.toString());
        } catch (error: any) {
            console.error("Storage: Failed to set quantity sum from localStorage.", error);
        }
    }

    static removeQuantitySum(): void {
        const quantitySum = this.getQuantitySum();

        if (quantitySum === undefined) {
            return;
        }

        try {
            window.localStorage.removeItem(this.CART_QUANTITY_SUM_KEY);
            console.debug("Storage: Removed quantity sum from localStorage.", quantitySum);
        } catch (error: any) {
            console.error("Storage: Failed to remove quantity sum from localStorage.", error);
        }
    }

    // ------------------------- [ Sale Source Tracking (SST) ] -------------------------

    static getSaleSourceTrackingId(): string | undefined {
        return Cookies.get(this.SALE_SOURCE_TRACKING_ID_KEY);
    }

    static removeSaleSourceTrackingId(): void {
        const sstId = this.getSaleSourceTrackingId();

        if (!sstId) {
            return;
        }

        this.remove(this.SALE_SOURCE_TRACKING_ID_KEY, config.COOKIE_DOMAIN_WILDCARD);
    }

    // ------------------------- [ Onnit Visitor Session ] -------------------------

    static startOnnitVisitorSession(): void {
        if (this.getOnnitVisitorUuid() !== undefined) {
            return;
        }

        Cookies.set(this.ONNIT_VISITOR_UUID_KEY, uuidv4(), {
            domain: config.COOKIE_DOMAIN,
            expires: 1, // In days.
        });
    }

    static getOnnitVisitorUuid(): string | undefined {
        const uuid = Cookies.get(this.ONNIT_VISITOR_UUID_KEY);

        if (uuid !== undefined && !this.isValidUuid(uuid)) {
            console.warn("Storage: Invalid Visitor UUID found in '%s' cookie.", this.ONNIT_VISITOR_UUID_KEY);
            Cookies.remove(this.ONNIT_VISITOR_UUID_KEY, {
                domain: config.COOKIE_DOMAIN,
            });
            return undefined;
        }

        return uuid;
    }

    private static isValidUuid(sessionId: string): boolean {
        return uuidValidate(sessionId) && uuidVersion(sessionId) === 4;
    }

    // ------------------------- [ Affiliate Tracking Code ] -------------------------

    static removeAffiliateTrackingCode(): void {
        this.remove(this.AFFILIATE_TRACKING_CODE_KEY, config.COOKIE_DOMAIN_WILDCARD);
    }

    // ------------------------- [ Follow Header Sale (FHS) ] -------------------------

    static removeAllFhs(): void {
        this.remove(this.FHS_SLUG_KEY, config.COOKIE_DOMAIN_WILDCARD);
        this.remove(this.FHS_MSG_KEY, config.COOKIE_DOMAIN_WILDCARD);
        this.remove(this.FHS_PERCENT_KEY, config.COOKIE_DOMAIN_WILDCARD);
    }

    // ------------------------- [ Private Methods ] -------------------------

    private static remove(cookieName: string, cookieDomain: string): void {
        Cookies.remove(cookieName, {
            domain: cookieDomain,
        });

        console.debug("Storage: Removed cookie '%s'.", cookieName);
    }
}
