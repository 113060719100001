import React, { useEffect } from "react";

const LoyaltyLionPoints:React.FC<React.PropsWithChildren<{ value: number }>> = ({ value }) => {
    useEffect(() => {
        window.loyaltylion?.ui?.refresh();
    }, [value]);

    // Loyalty Lion documentation: The attribute value should be in cents, as the component divides
    // the amount by 100 before applying the purchase rule multiplier. @see https://developers.loyaltylion.com/sdk/components/points-for-cart
    return (
        <span data-lion-points-for={value * 100} />
    );
};

export default LoyaltyLionPoints;
