enum CustomerGroupIdEnum {
    // There are more group ID's, but these are all we care about in this project.
    GUEST = "G", // Deprecated and only present to signify it can be a string.
    MILITARY = 3, // Used for both military.
    MEDICAL = 14, // Used for both nurses and medical professionals.
    RESPONDER = 18, // Used for first responders.
    TEACHER = 19, // Used for teachers.
}

export default CustomerGroupIdEnum;
