import { AxiosResponse } from "axios";
import AbstractCartClient from "./AbstractCartClient";
import ShippingQuoteSelectedAssociation from "../../interfaces/shipping-quote/ShippingQuoteSelectedAssociation";
import Cart from "../../interfaces/cart/Cart";
import { ShippingQuoteResponse } from "../../interfaces/shipping-quote/ShippingQuoteGroup";
import GetCartConfig from "../../interfaces/cart/GetCartConfig";

export default class CartShippingClient extends AbstractCartClient {
    async getShippingQuotes(cartUuid: string, config: GetCartConfig): Promise<AxiosResponse<ShippingQuoteResponse>> {
        const url = `${cartUuid}/shipping-quotes`;

        return this.axios.get(url, {
            params: config
        });
    }

    async updateShippingQuotesSelected(cartUuid: string, associations: ShippingQuoteSelectedAssociation[]): Promise<AxiosResponse<Cart>> {
        const url = `${cartUuid}/shipping-quotes/selected`;

        return this.axios.put(url, {
            shipping_quotes_selected: associations,
        });
    }
}
