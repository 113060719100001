import { EnteredCouponConfig } from "../../interfaces/coupon/EnteredCouponConfig";
import AbstractCouponEvent from "./AbstractCouponEvent";

export default abstract class AbstractCouponEnteredEvent extends AbstractCouponEvent {
    readonly cart_uuid: string | null;

    constructor(config: EnteredCouponConfig) {
        super(config.code);
        this.cart_uuid = config.cart_uuid;
    }
}
