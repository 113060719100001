import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Text, SectionHeader } from "@onnit-js/ui/components/text";
import { Link } from "react-router-dom";
import CartInterface from "../../../interfaces/cart/Cart";
import CartProductListMutable from "./product/CartProductListMutable";
import CartTotalList from "./total/CartTotalList";
import CartProductListImmutable from "./product/CartProductListImmutable";
import CouponInputContainer from "../coupon/CouponInputContainer";
import FreeGiftsContainer from "./product/free-gifts/FreeGiftsContainer";
import AmazonPersonalizeContainer from "./product/amz-personalize/AmazonPersonalizeContainer";
import IdMeContainer from "../payment/IdMeContainer";
import PreUpsellList from "../upsells/pre/PreUpsellList";
import RouteEnum from "../../../enums/RouteEnum";

interface Props {
    cart: CartInterface;
    canMutateProducts?: boolean;
    canAddCoupons?: boolean;
    canRemoveCoupons?: boolean;
    showAmazonPersonalize?: boolean;
    showFreeGifts?: boolean;
    showProductWarningSymbols?: boolean;
    showHeader?: boolean;
    showChangeLink?: boolean;
    showIdMe?: boolean;
    showPreUpsells?: boolean;
    showSubscriptionSwitch?: boolean;
}

const Cart = (props: Props) => {
    const {
        cart,
        canMutateProducts = true,
        canAddCoupons = true,
        canRemoveCoupons = true,
        showAmazonPersonalize = true,
        showFreeGifts = true,
        showProductWarningSymbols = false,
        showHeader = true,
        showIdMe = true,
        showPreUpsells = false,
        showChangeLink = true,
        showSubscriptionSwitch = true,
    } = props;

    return (
        <>
            {showHeader && (
                <SectionHeader title="Order summary">
                    {showChangeLink && (
                        <Link to={RouteEnum.CART_PAGE}>
                            <Text>Change</Text>
                        </Link>
                    )}
                </SectionHeader>
            )}
            <Box>
                {showFreeGifts && (
                    <FreeGiftsContainer cart={cart} />
                )}
                {canMutateProducts ? (
                    <CartProductListMutable cart={cart} />
                ) : (
                    <CartProductListImmutable
                        cart={cart}
                        showProductWarningSymbols={showProductWarningSymbols}
                        showSubscriptionSwitch={showSubscriptionSwitch}
                    />
                )}
                {showPreUpsells && (
                    <PreUpsellList />
                )}
                {showAmazonPersonalize && (
                    <AmazonPersonalizeContainer cart={cart} canAddProducts={canMutateProducts} />
                )}
                {canAddCoupons && (
                    <Box py={5} borderBottom="1px solid" borderColor="grays.1">
                        <CouponInputContainer cart={cart} />
                        {showIdMe && <IdMeContainer />}
                    </Box>
                )}

                <CartTotalList cart={cart} canRemoveCoupons={canRemoveCoupons} />
            </Box>
        </>
    );
};

export default Cart;
