import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import BraintreeService from "@onnit-js/ui/services/braintree/BraintreeService";
import CreateOrderResult from "../interfaces/order/CreateOrderResult";
import CartOrderClient from "../clients/cart/CartOrderClient";
import Cart from "../interfaces/cart/Cart";
import CreateOrderConfig from "../interfaces/order/CreateOrderConfig";
import ThunkAction from "../interfaces/ThunkAction";
import eventEmitter from "../events/eventEmitter";
import OrderCreatedEvent from "../events/events/OrderCreatedEvent";
import {
    addPaymentMethod,
    removePaymentTokenizedMethod,
    setPaymentError,
    setPaymentTokenizedMethodSelected
} from "./paymentSlice";
import CreateOrderConfigFactory from "../services/CreateOrderConfigFactory";
import CartBrowserStorageService from "../services/CartBrowserStorageService";
import { setAppMessages, setIsLoading } from "./appSlice";
import ErrorMessageEnum from "../enums/ErrorMessageEnum";
import ErrorCodeEnum from "../enums/ErrorCodeEnum";
import CartMessageLevelEnum from "../enums/CartMessageLevelEnum";
import PageNameEnum from "../enums/PageNameEnum";
import { clearCart, loadCart } from "./cartSlice";
import { ExpertVoiceFormData } from "../components/checkout/review/ExpertVoice";

const initialState: CreateOrderResult | null = null;

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        setOrder(state, action: PayloadAction<CreateOrderResult>) {
            return action.payload as any;
        }
    }
});

export const { setOrder } = orderSlice.actions;

export default orderSlice;

// ------------------------- [ Thunks ] -------------------------

const orderClient = new CartOrderClient();

export const createOrder = (cart: Cart, config: CreateOrderConfig): ThunkAction<Promise<CreateOrderResult>> => (
    async (dispatch) => {
        const response = await orderClient.chargeAndCreateOrder(cart.cart_uuid, config);
        const order = response.data;

        dispatch(setOrder(order));

        // For safety, don't include this in the event.
        const orderWithoutReset = omit(response.data, "password_reset");
        eventEmitter.emit(new OrderCreatedEvent(cart, orderWithoutReset));

        return order;
    }
);

export const placeOrder = (btService: BraintreeService, expertVoiceFormData?: ExpertVoiceFormData): ThunkAction<Promise<CreateOrderResult | null>> => (
    async (dispatch, getState) => {
        const { cart } = getState();
        const { payment } = getState();
        const { tokenizedMethodSelected } = payment;

        if (!cart) {
            console.error("Failed to create order. Cart is undefined.");
            return null;
        }

        try {
            // Guests or customers with no saved payment methods
            // won't have a tokenizedMethodSelected because there is no
            // continue button in the PaymentSection.  we must call addPaymentMethod first.
            const paymentState = btService && !tokenizedMethodSelected ? await dispatch(addPaymentMethod(btService)) : payment;
            if (!paymentState) {
                dispatch(setPaymentError("Please select a payment method"));
                console.error("Failed to create order. Payment is undefined.", paymentState);
                return null;
            }
            const config = CreateOrderConfigFactory.make(cart, paymentState);

            config.visitor_uuid = CartBrowserStorageService.getOnnitVisitorUuid();
            if (config.visitor_uuid === undefined) {
                console.warn("Failed to get Onnit Visitor UUID");
            }

            config.sale_source_tracking_id = CartBrowserStorageService.getSaleSourceTrackingId();

            if (expertVoiceFormData) {
                config.expertvoice_retailer_name = expertVoiceFormData.expertVoiceRetailerName;
                config.expertvoice_retailer_store = expertVoiceFormData.expertVoiceRetailerStore;
            }

            dispatch(setIsLoading(true));
            const createOrderResult = await dispatch(createOrder(cart, config));
            dispatch(setIsLoading(false));

            CartBrowserStorageService.removeCartValues();
            CartBrowserStorageService.removeTrackingValues(); // These should only be used on the first order.

            return createOrderResult;
        } catch (error: any) {
            console.error("Failed to create order.", error);
            // Re-get the tokenized method selected to handle the case where
            // addPaymentMethod was called above because there was no
            // tokenizedMethodSelected initially (guest or no saved payment methods).
            const { tokenizedMethodSelected } = getState().payment;

            // The error object may not contain a response object.
            const errorMsg = error?.response?.data?.error_message ?? ErrorMessageEnum.GENERIC;
            const errorCode = error?.response?.data?.error_code;

            // If a payment error, set the error in GlobalState
            if (errorCode === ErrorCodeEnum.PAYMENT_CHARGE) {
                dispatch(setPaymentError(errorMsg));
                // If a payment method nonce was used, remove it from state to force the customer to reenter the payment method.
                // This is because nonces, by nature, cannot be used more than once.
                if (tokenizedMethodSelected?.nonce) {
                    dispatch(removePaymentTokenizedMethod(tokenizedMethodSelected));
                    dispatch(setPaymentTokenizedMethodSelected(null));
                }
                return null;
            }

            if (errorCode === ErrorCodeEnum.NOT_FOUND) {
                dispatch(setAppMessages([
                    {
                        code: null,
                        message_html: errorMsg,
                        level: CartMessageLevelEnum.ERROR,
                        pages_visible: [PageNameEnum.CART],
                    },
                ]));

                dispatch(clearCart());
                return null;
            }

            // If a validation error occurred, re-load the cart to re-validate it.
            if (errorCode === ErrorCodeEnum.VALIDATION) {
                await dispatch(loadCart(cart.cart_uuid));
            }

            // Re-throw error message to be displayed by component.
            throw errorMsg;
        } finally {
            dispatch(setIsLoading(false));
        }
    });
