import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import CartValidationCollection from "../../../../interfaces/cart/validation/CartValidationCollection";
import CartValidationMessage from "../../../../interfaces/cart/validation/CartValidationMessage";
import RemovedProductsText from "./RemovedProductsText";
import CartMessage from "../../CartMessage";
import CartMessageLevelEnum from "../../../../enums/CartMessageLevelEnum";

interface Props {
    validation: CartValidationCollection;
}

const getUniqueMessages = (messages: CartValidationMessage[]): string[] => {
    const set = new Set(messages.map((message) => message.message));

    return Array.from(set);
};

const ValidationContainer = (props: Props) => {
    const { validation } = props;
    const removedProductNames = validation.removed_product_names;
    const uniqueMessages = getUniqueMessages(validation.messages);

    return uniqueMessages.length > 0 ? (
        <Box p={4}>
            {uniqueMessages.map((message) => (
                <CartMessage
                    key={message}
                    level={CartMessageLevelEnum.ERROR}
                    messageHtml={message}
                />
            ))}
            {removedProductNames.length > 0 && (
                <RemovedProductsText productNames={removedProductNames} />
            )}
        </Box>
    ) : null;
};

export default ValidationContainer;
