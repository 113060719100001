import BraintreeTokenizedMethod from "@onnit-js/ui/services//braintree/types/BraintreeTokenizedMethod";
import BraintreeTokenizedPaymentMethodTypeEnum from "@onnit-js/ui/services//braintree/types/BraintreeTokenizedPaymentMethodTypeEnum";
import BraintreeTokenizedCardTypeEnum from "@onnit-js/ui/services//braintree/types/BraintreeTokenizedCardTypeEnum";
import PaymentMethod from "@onnit-js/ui/@types/interfaces/payment-method/PaymentMethod";
import PaymentMethodTypeEnum from "@onnit-js/ui/@types/enums/payment-method/PaymentMethodTypeEnum";
import PaymentMethodCardTypeEnum from "@onnit-js/ui/@types/enums/payment-method/PaymentMethodCardTypeEnum";
import PaymentMethodInstrumentEnum from "../enums/payment/PaymentMethodInstrumentEnum";
import TokenizedPaymentMethod from "../interfaces/payment/TokenizedPaymentMethod";
import PaymentGatewayIdEnum from "../enums/payment/PaymentGatewayIdEnum";

export default class TokenizedPaymentMethodFactory {
    static readonly BT_CARD_TYPE_TO_INSTRUMENT = {
        [BraintreeTokenizedCardTypeEnum.AMEX]: PaymentMethodInstrumentEnum.AMEX,
        [BraintreeTokenizedCardTypeEnum.DISCOVER]: PaymentMethodInstrumentEnum.DISCOVER,
        [BraintreeTokenizedCardTypeEnum.MASTERCARD]: PaymentMethodInstrumentEnum.MASTERCARD,
        [BraintreeTokenizedCardTypeEnum.VISA]: PaymentMethodInstrumentEnum.VISA,
    };

    static readonly BT_CARD_TYPE_TO_LABEL = {
        [BraintreeTokenizedCardTypeEnum.AMEX]: "American Express",
        [BraintreeTokenizedCardTypeEnum.DISCOVER]: "Discover",
        [BraintreeTokenizedCardTypeEnum.MASTERCARD]: "MasterCard",
        [BraintreeTokenizedCardTypeEnum.VISA]: "Visa",
    };

    static fromBtTokenizedMethod(btMethod: BraintreeTokenizedMethod, instrumentType: PaymentMethodTypeEnum): TokenizedPaymentMethod {
        return {
            payment_gateway_id: PaymentGatewayIdEnum.BRAINTREE,
            instrument: this.getBraintreeInstrument(btMethod),
            instrument_type: instrumentType,
            credit_card_last_four: btMethod.details.lastFour,
            card_type_label: this.BT_CARD_TYPE_TO_LABEL[(btMethod.details.cardType as BraintreeTokenizedCardTypeEnum)] ?? null,
            paypal_email: btMethod.details.email,
            nonce: btMethod.nonce,
        };
    }

    static fromStoredPaymentMethod(method: PaymentMethod): TokenizedPaymentMethod {
        return {
            payment_gateway_id: PaymentGatewayIdEnum.BRAINTREE,
            instrument: this.getStoredInstrument(method),
            instrument_type: method.method_type,
            credit_card_last_four: method.card_number_last4,
            card_type_label: method.card_type_label,
            paypal_email: method.paypal_email,
            method_token: method.braintree_token,
            card_expires_at: method.card_expires_at,
        };
    }

    // ----------[ Private Methods ]----------

    private static getBraintreeInstrument(method: BraintreeTokenizedMethod): PaymentMethodInstrumentEnum {
        const { type, details } = method;
        let instrument = null;

        if (type === BraintreeTokenizedPaymentMethodTypeEnum.CREDIT_CARD) {
            instrument = this.BT_CARD_TYPE_TO_INSTRUMENT[(details.cardType as BraintreeTokenizedCardTypeEnum)];
        } else if (type === BraintreeTokenizedPaymentMethodTypeEnum.PAYPAL) {
            instrument = PaymentMethodInstrumentEnum.PAYPAL;
        }

        return instrument || PaymentMethodInstrumentEnum.OTHER;
    }

    private static getStoredInstrument(method: PaymentMethod): PaymentMethodCardTypeEnum | PaymentMethodInstrumentEnum {
        const { method_type, card_type } = method;
        let instrument = null;

        if (method_type === PaymentMethodTypeEnum.CREDIT_CARD) {
            instrument = card_type;
        } else if (method_type === PaymentMethodTypeEnum.PAYPAL) {
            instrument = PaymentMethodInstrumentEnum.PAYPAL;
        } else if (method_type === PaymentMethodTypeEnum.APPLE_PAY) {
            // Here for posterity however Apple Pay should never be returned from the API within cart-ui.
            instrument = PaymentMethodInstrumentEnum.APPLE_PAY;
        }

        return instrument || PaymentMethodInstrumentEnum.OTHER;
    }
}
