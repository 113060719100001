import { AxiosResponse } from "axios";
import CreateOrderConfig from "../../interfaces/order/CreateOrderConfig";
import CreateOrderResult from "../../interfaces/order/CreateOrderResult";
import AbstractCartClient from "./AbstractCartClient";

export default class CartOrderClient extends AbstractCartClient {
    protected getTimeout(): number {
        return 60 * 1000;
    }

    async chargeAndCreateOrder(cartUuid: string, config: CreateOrderConfig): Promise<AxiosResponse<CreateOrderResult>> {
        return this.axios.post(`${cartUuid}/orders`, config);
    }

    protected withCredentials(): boolean {
        return true;
    }
}
