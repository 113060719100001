enum PageNameEnum {
    CART = "CART",
    EXPRESS_CHECKOUT = "EXPRESS_CHECKOUT",
    SHIPPING_ADDRESS = "SHIPPING_ADDRESS",
    SHIPPING_SPEED = "SHIPPING_SPEED",
    PAYMENT = "PAYMENT",
    REVIEW = "REVIEW",
    SUCCESS = "SUCCESS",
}

export default PageNameEnum;
