const supportedBrowsers = Object.freeze({
    // These are our officially supported browsers derived according to https://browsersl.ist/ and entering our package.json browserslist config.
    // Format of this list MUST match: https://github.com/lancedikson/bowser#browser-names-for-satisfies
    // Browsers NOT on this list are implicitly supported.
    edge: ">=122",
    chrome: ">=109",
    safari: ">=15",
    firefox: ">=115",
    desktop: {
        opera: ">=109",
    },
    mobile: {
        android: ">=127",
        blackberry: ">=10",
        samsung_internet: ">=24",
        // Excludes Opera Mini, which has poor ES5 support.
        opera: ">=80",
    },
});

export default supportedBrowsers;
