import EventEmitter from "events";
import { forEach } from "lodash";
import EventListenerMap from "./EventListenerMap";
import AbstractEvent from "./events/AbstractEvent";

/**
 * Wrap the Node.js event emitter in our own class to control which methods on the EventEmitter can be called.
 * This enforces listeners to be attached in a single place here, instead of sprinkled all over the codebase.
 */
class CartEventEmitter {
    private readonly emitter: EventEmitter;

    constructor(emitter: EventEmitter) {
        this.emitter = emitter;
        this.attachListeners();
    }

    private attachListeners = () => {
        forEach(EventListenerMap, (listeners, eventName) => {
            listeners.forEach((listener) => {
                this.emitter.on(eventName, listener);
            });
        });
    };

    emit = (event: AbstractEvent) => {
        const eventName = event.getName();

        try {
            this.emitter.emit(eventName, event);
            console.debug(`Event: Emitted cart event '${eventName}'.`, event);
         } catch (error: any) {
            console.error(`Event: Failed to emit cart event '${eventName}'.`, event, error);
        }
    };
}

const eventEmitter = new CartEventEmitter(new EventEmitter());

// Export the singleton.
export default eventEmitter;
