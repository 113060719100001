import { AxiosResponse } from "axios";
import AddressRecord from "@onnit-js/ui/@types/interfaces/address/AddressRecord";
import CreateUpdateAddressResponse from "@onnit-js/ui/@types/interfaces/address/CreateUpdateAddressResponse";
import CreateUpdateAddressCommand from "@onnit-js/ui/@types/interfaces/address/CreateUpdateAddressCommand";
import config from "../config";
import AbstractClient from "./AbstractClient";

export default class AddressClient extends AbstractClient {
    protected getBaseUrl(): string {
        return `${config.API_URL}/addresses`;
    }

    async listAddresses(customerId: number): Promise<AxiosResponse<AddressRecord[]>> {
        return this.axios.get("", {
            params: {
                customer_id: customerId,
            },
        });
    }

    async createAddress(config: CreateUpdateAddressCommand): Promise<AxiosResponse<CreateUpdateAddressResponse>> {
        return this.axios.post("", config);
    }

    async updateAddress(address: AddressRecord): Promise<AxiosResponse<CreateUpdateAddressResponse>> {
        return this.axios.put(`/${address.id}`, address);
    }

    protected withCredentials(): boolean {
        // These requests REQUIRE a valid authentication token cookie.
        return true;
    }
}
