import React, { useState } from "react";
import { Text } from "@onnit-js/ui/components/text";
import qs from "qs";
import { intersection } from "lodash";
import IdMeVerifyButton from "./IdMeVerifyButton";
import RouteEnum from "../../../enums/RouteEnum";
import config from "../../../config";
import CustomerGroupIdEnum from "../../../enums/CustomerGroupIdEnum";
import { useAppSelector } from "../../../configureStore";

const IdMeContainer = () => {
    const cart = useAppSelector((state) => state.cart);
    const customer = cart?.customer;
    const [isOpen, setIsOpen] = useState(false);
    const isIdMeCustomerGroup = !!(customer && intersection([
        CustomerGroupIdEnum.MILITARY,
        CustomerGroupIdEnum.MEDICAL,
        CustomerGroupIdEnum.RESPONDER,
        CustomerGroupIdEnum.TEACHER],
    customer.group_ids).length
    );

    // Hide button if already associated with ID.me group.
    if (isIdMeCustomerGroup) {
        return null;
    }

    if (!isOpen) {
        return (
            <Text
                as="button"
                color="grays.4"
                fontSize={0}
                textAlign="center"
                p={[0, 0, 2]}
                lineHeight={6}
                onClick={() => setIsOpen(true)}
                width={1}
            >
                Military, First Responder, Medical & Teacher discounts
            </Text>
        );
    }

    if (!customer) {
        // Redirect back to PaymentPage after signing in or creating an account.
        const queryString = qs.stringify({
            next: `/cart${RouteEnum.CHECKOUT_PAGE}`,
        });
        const loginUrl = `${config.ALLIT_URL}/cart/login.php?${queryString}`;
        const createUrl = `${config.ALLIT_URL}/cart/create_account.php?${queryString}`;

        return (
            <Text color="grays.4" fontSize={0} textAlign="center" p={2}>
                Please <a href={loginUrl}>sign in</a> or <a href={createUrl}>create an account</a> to verify your
                affiliation.
            </Text>
        );
    }

    return <IdMeVerifyButton customerId={customer.customer_id} />;
};

export default IdMeContainer;
