import React from "react";
import { Text } from "@onnit-js/ui/components/text";
import { BoxProps } from "@onnit-js/ui/components/box/Box";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { MdInfoOutline } from "react-icons/md";
import Tooltip from "@onnit-js/ui/components/tooltip/Tooltip";
import Bubble from "../../../shared/oui/bubble/Bubble";
import CartPromoDiscountConfig from "../../../../interfaces/cart/CartPromoDiscountConfig";

interface PromoDiscountLabelProps extends BoxProps {
    promoDiscount: CartPromoDiscountConfig;
    productId: number;
    isActive: boolean;
}

interface PromoDiscountBubbleProps extends PromoDiscountLabelProps {
    isActive: boolean
}

export const promoIsActivelyDiscounted = (productId: number, promo?: CartPromoDiscountConfig): boolean => (
    !!promo?.active_discounted_product_ids.includes(productId)
);

const PromoDiscountBubble: React.FC<React.PropsWithChildren<PromoDiscountBubbleProps>> = ({ isActive, promoDiscount, ...rest }) => {
    const text = isActive ? " Applied" : " Eligible";
    const bgColor = isActive ? "earthGreen" : "grays.3";
    const textColor = isActive ? "earthGreens.8" : "grays.7";

    return (
        <Tooltip
            style={{ zIndex: 99999999 }}
            content={(
                <>
                    <Text
                        color="white"
                        fontSize={1}
                        fontWeight="bold"
                        display="block"
                        mb={1}
                        dangerouslySetInnerHTML={{ __html: promoDiscount.title }}
                    />
                    <Text color="white" fontSize={1} dangerouslySetInnerHTML={{ __html: promoDiscount.description }} />
                </>
            )}
        >
            <Bubble bg={bgColor} height="22px" alignItems="center" {...rest}>
                <Text color={textColor} fontSize="12px">
                    Promo {text}
                </Text>
                <Icon
                    size={16}
                    color={textColor}
                    icon={MdInfoOutline}
                    ariaLabel="Promotion Info"
                    transform="translate(1px, -1px)"
                    title="Promo Terms"
                />
            </Bubble>
        </Tooltip>
    );
};

const PromoDiscountLabel: React.FC<React.PropsWithChildren<PromoDiscountLabelProps>> = (props) => {
    const { promoDiscount, productId } = props;
    const show = props.isActive
        || ((promoDiscount.eligible_promo_product_ids.includes(productId)
                || promoDiscount.eligible_discount_product_ids.includes(productId))
            && !promoDiscount.active_promo_product_ids.includes(productId));

    return show ? <PromoDiscountBubble {...props} /> : null;
};

export default PromoDiscountLabel;
