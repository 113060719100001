import { CartProducts } from "../../interfaces/cart/CartProduct";
import Cart from "../../interfaces/cart/Cart";
import AbstractEvent from "./AbstractEvent";

export default class PostUpsellProductsRemovedEvent extends AbstractEvent {
    static readonly eventName: string = "PostUpsellProductsRemoved";

    readonly cartProductsRemoved: CartProducts;

    readonly cart: Cart;

    constructor(cartProducts: CartProducts, cart: Cart) {
        super();
        this.cartProductsRemoved = cartProducts;
        this.cart = cart;
    }

    getName(): string {
        return PostUpsellProductsRemovedEvent.eventName;
    }
}
