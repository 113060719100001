import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ThunkAction from "../interfaces/ThunkAction";
import { CartUpsellConfig } from "../interfaces/cart/CartUpsell";
import CartProductClient from "../clients/cart/CartProductClient";
import RouteEnum from "../enums/RouteEnum";
import eventEmitter from "../events/eventEmitter";
import PageInitializedEvent from "../events/events/PageInitializedEvent";

const initialState:CartUpsellConfig = { layout: null, upsells: [] };

const upsellsSlice = createSlice({
    name: "upsells",
    initialState,
    reducers: {
        setUpsells(state:CartUpsellConfig, action:PayloadAction<CartUpsellConfig>) {
            state.upsells = action.payload.upsells;
            return state;
        }
    }
});

export const { setUpsells } = upsellsSlice.actions;

export default upsellsSlice;

// ------------------------- [ Thunks ] -------------------------

const cartProductClient = new CartProductClient();

export const loadUpsells = (cartUuid:string): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const { cart } = getState();
    const response = await cartProductClient.getUpsells(cartUuid);
    dispatch(setUpsells(response.data));

    // There is no pre upsell page anymore, send tracking event if we have upsell data.
    if (cart && response.data.upsells.length > 0) {
        eventEmitter.emit(new PageInitializedEvent(cart, RouteEnum.UPSELLS));
    }
};
