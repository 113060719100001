enum RouteEnum {
    // Router basename is "/cart".
    CART_PAGE = "/",
    CHECKOUT_PAGE = "/checkout",
    SHIPPING_ADDRESS = "/checkout/shipping-address",
    SHIPPING_SPEED = "/checkout/shipping-speed",
    PAYMENT = "/checkout/payment",
    UPSELLS = "/checkout/offers",
    REVIEW = "/checkout/review",
    EXCLUSIVE_OFFERS = "/checkout/exclusive-offers",
    SUCCESS = "/checkout/success",
}

export default RouteEnum;
