import { AxiosResponse } from "axios";
import AbstractCartClient from "./AbstractCartClient";
import Cart from "../../interfaces/cart/Cart";
import UpdateAddressIdsConfig from "../../interfaces/address/UpdateAddressIdsConfig";

export default class CartAddressClient extends AbstractCartClient {
    async updateCartAddressIds(cartUuid: string, config: UpdateAddressIdsConfig): Promise<AxiosResponse<Cart>> {
        const url = `${cartUuid}/addresses`;

        if (!config.shipping_address_id && !config.billing_address_id) {
            throw new Error("At least one shipping/billing address ID is required.");
        }

        return this.axios.put(url, config);
    }
}
