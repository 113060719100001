// Note: These are for issues occurring exclusively in the UI.
//       Backend error messages should be returned by the API.
enum ErrorMessageEnum {
    GENERIC = "Sorry, an error occurred. Please try again or contact us for assistance.",
    PAYMEMT_BILLING_MISSING = "Please provide a billing address and try again.",
    PAYMENT_CARD_VALIDATION = "The card is invalid. Please check your information and try again.",
    POST_UPSELL_REMOVE = "Sorry, an error occurred while removing the limited time offer. Please try again or contact us for assistance.",
    POST_UPSELL_DECLINE = "Sorry, an internal error occurred with the limited time offer. Your original order details are below.",
}

export default ErrorMessageEnum;
