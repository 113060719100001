import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { addProduct } from "../../../../../slices/cartSlice";
import { setIsLoading } from "../../../../../slices/appSlice";
import { FreeGiftGrouping } from "../../../../../interfaces/cart/FreeGift";
import FreeGiftGroup from "./FreeGiftGroup";
import FreeGiftTypeEnum from "../../../../../enums/FreeGiftTypeEnum";

interface Props extends PropsFromRedux {
    cartUuid: string;
    freeGiftGroups: FreeGiftGrouping[];
}

const FreeGiftAddBoxList = ({ freeGiftGroups, ...props }: Props) => {
    const onAddClick = async (productId: number) => {
        props.setIsLoading(true);

        await props.addProduct({
            product_id: productId,
            quantity: 1,
            subscription_interval: null,
            product_stock_priority_token: null,
        }, props.cartUuid, "Cart Free Gifts");

        props.setIsLoading(false);
    };

    return (
        <>
            {
                freeGiftGroups.map((group, index) => {
                    const freeGifts = group[1];
                    if (freeGifts.length === 0) {
                        return null;
                    }

                    const { product, virtual_free_gift, ...groupProps } = freeGifts[0];

                    if (!groupProps.is_visible_in_cart) {
                        return null;
                    }

                    if (groupProps.free_gift_type === FreeGiftTypeEnum.PRODUCT && !product) {
                        return null;
                    }

                    if (groupProps.free_gift_type !== FreeGiftTypeEnum.PRODUCT && !virtual_free_gift) {
                        return null;
                    }

                    return (
                        <FreeGiftGroup
                            // Index here is the only thing we can rely on for uniqueness since name can be null
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${groupProps.name}_${groupProps.eligibility_rule_id}_${index}`}
                            name={groupProps.name ?? product?.name ?? virtual_free_gift?.name ?? ""}
                            description={groupProps.description}
                            freeGiftType={groupProps.free_gift_type}
                            threshold={groupProps.threshold ?? undefined}
                            thresholdRemaining={groupProps.threshold_remaining ?? undefined}
                            freeGifts={freeGifts}
                            onAddClick={onAddClick}
                            withBorder={index + 1 < freeGiftGroups.length}
                        />
                    );
                })
            }
        </>
    );
};

const mapDispatchToProps = {
    addProduct,
    setIsLoading,
};

const connector = connect(
    null,
    mapDispatchToProps,
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FreeGiftAddBoxList);
