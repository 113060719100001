import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";

export default styled.span`
    position: absolute;
    display: block;
    box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.1875);
    font-family: ${themeGet("fonts.primary")};
    top: 0;
    right: 0;
    color: black;
    border-radius: 24px;
    font-size: 14px;
    text-align: center;
    padding: 6px 8px 3px 8px;
    min-width: 9px;
    line-height: 1.2;
    background-color: ${themeGet("colors.sunwashGold")};
    @media print {
        -webkit-print-color-adjust: exact !important;
        background-color: ${themeGet("colors.grays.6")};
    }
`;
