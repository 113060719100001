// The possible error code values returned by the cart API for erroneous responses.
// Should stay in sync with `CartResponseErrorCodeEnum` in Atlas API.
enum ErrorCodeEnum {
    NOT_FOUND = "NOT_FOUND",
    VALIDATION = "VALIDATION",
    COUPON_NOT_APPLICABLE = "COUPON_NOT_APPLICABLE",
    PAYMENT_CHARGE = "PAYMENT_CHARGE",
    INTERNAL_ERROR = "INTERNAL_ERROR",
}

export default ErrorCodeEnum;
