// See: https://dev.to/timber/wait-for-a-script-to-load-in-javascript-579k
export default class ScriptLoaderService {
    private readonly url: string;

    constructor(url: string) {
        this.url = url;
    }

    load = async (): Promise<HTMLScriptElement> => new Promise((resolve, reject) => {
        const script = this.createScript();

        // Resolve the promise when it's finished loading.
        script.addEventListener("load", () => {
            resolve(script);
        });

        // Reject the promise if there was an error.
        script.addEventListener("error", () => {
            reject(new Error(`Failed to load script '${this.url}'.`));
        });

        // Inject it in the head element to begin loading it.
        document.head.appendChild(script);
    });

    private createScript = (): HTMLScriptElement => {
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.defer = true;
        script.src = this.url;

        return script;
    };
}
