import React, { Component } from "react";
import ScriptLoaderService from "@onnit-js/ui/services//ScriptLoaderService";
import config from "../../../config";

interface Props {
    onRendered: (captchaId: number) => void;
    onSuccess: (responseToken: string) => void;
}

export default class GoogleReCaptcha extends Component<Props> {
    private readonly containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.containerRef = React.createRef(); // So we can pass the DOM element to the SDK.
    }

    private async loadSdk(): Promise<HTMLScriptElement> {
        const url = `${config.GOOGLE_RECAPTCHA_SDK_URL}?render=explicit`;
        const loader = new ScriptLoaderService(url);

        return loader.load();
    }

    private isSdkLoaded(): boolean {
        return !!(window.grecaptcha?.render);
    }

    private renderCaptcha() {
        if (!this.containerRef.current) {
            throw new Error("Failed to render captcha because the container ref is null.");
        }

        const captchaId = window.grecaptcha.render(this.containerRef.current, {
            sitekey: config.GOOGLE_RECAPTCHA_PUBLIC_KEY,
            theme: "light",
            size: "normal",
            callback: this.props.onSuccess,
        });
        this.props.onRendered(captchaId);
    }

    // ------------------------- [ Lifecycle Methods ] -------------------------

    componentDidMount(): void {
        // It could have already been loaded if this component was unmounted and later mounted again.
        if (this.isSdkLoaded()) {
            this.renderCaptcha();
            return;
        }

        this.loadSdk()
            .then((script) => {
                console.debug("Successfully loaded Google reCAPTCHA SDK.", script);

                // Even after the SDK JS has loaded, Google does more initialization, then calls this function.
                window.grecaptcha.ready(() => this.renderCaptcha());
            })
            .catch((error) => {
                console.error("Failed to load Google reCAPTCHA SDK.", error);
            });
    }

    render() {
        return (
            <div ref={this.containerRef} />
        );
    }
}
