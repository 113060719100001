import React, { FC } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Cart from "../../../../../interfaces/cart/Cart";
import CartHeading from "../../CartHeading";
import FreeGiftAddBoxList from "./FreeGiftAddBoxList";
import FreeGiftGroup from "./FreeGiftGroup";
import { FreeGiftGrouping } from "../../../../../interfaces/cart/FreeGift";

interface Props {
    cart: Cart;
}

const FreeGiftsContainer: FC<React.PropsWithChildren<Props>> = ({ cart }) => {
    let count = cart.free_gifts.length;
    const showFreeShipping = (!cart.shipping_address || cart.free_shipping_eligibility.is_shipping_address_eligible);

    // Add to free gift count if shipping address is eligible for free shipping
    if (showFreeShipping) {
        count += 1;
    }

    if (count === 0) {
        return null;
    }

    // Group free gifts by eligibility_rule_id if they should_group.
    // Using an array instead of grouping by eligibility_rule_id since there can
    // be multiple gifts with the same eligibility_rule_id. Ex. "COUPON_FREE_WITH_USE"
    const grouped: FreeGiftGrouping[] = [];

    cart.free_gifts.forEach((freeGift) => {
        if (freeGift.should_group) {
            const match = grouped.find((group) => group[0] === freeGift.eligibility_rule_id);
            if (match) {
                match[1].push(freeGift);
                return;
            }
        }
        grouped.push([freeGift.eligibility_rule_id, [freeGift]]);
    });

    return (
        <Box my={2}>
            <CartHeading>
                Your free gift{count > 1 ? "s" : ""}
            </CartHeading>

            {showFreeShipping && (
                <FreeGiftGroup
                    name="FREE Shipping"
                    description="Free shipping is only available in the contiguous U.S. and excludes Fitness and Digital items."
                    threshold={cart.free_shipping_eligibility.threshold}
                    thresholdRemaining={cart.free_shipping_eligibility.threshold_remaining}
                />
            )}

            <FreeGiftAddBoxList freeGiftGroups={grouped} cartUuid={cart.cart_uuid} />

            <CartHeading>
                Your bag items
            </CartHeading>
        </Box>
    );
};

export default FreeGiftsContainer;
