import Cart from "../interfaces/cart/Cart";
import CartProductConfig, { CartProductConfigs } from "../interfaces/cart/CartProductConfig";
import CartProduct, { CartProducts } from "../interfaces/cart/CartProduct";
import ProductUtil from "./ProductUtil";
import CartPromoDiscountConfig from "../interfaces/cart/CartPromoDiscountConfig";

export default class CartProductUtil {
    static getProductIds(cart: Cart): number[] {
        return cart.products.map((cartProduct) => cartProduct.product.id);
    }

    static getProduct = (cart: Cart, productConfig: CartProductConfig): CartProduct | undefined => (
        CartProductUtil.getProducts(cart, [productConfig])[0]
    );

    static getProducts = (cart: Cart, productConfigs: CartProductConfigs): CartProducts => {
        const productIds = new Set(productConfigs.map((config) => config.product_id));

        return cart.products.filter((cartProduct) => productIds.has(cartProduct.product.id));
    };

    static hasDigitalProduct = (cart: Cart): boolean => cart.products.some(ProductUtil.isDigitalProduct);

    static hasOnlyDigitalProducts = (cart: Cart): boolean => cart.products.every(ProductUtil.isDigitalProduct);

    static getPromoDiscount = (cart: Cart, cartProduct: CartProduct): CartPromoDiscountConfig | undefined => {
        if (cartProduct.free_gift_eligibility_rule_id) {
            return undefined;
        }

        return cart.promo_discounts.find((promo: CartPromoDiscountConfig) => (cartProduct.promo_discount_rule_id
                ? promo.promo_discounts_rule_id === cartProduct.promo_discount_rule_id
                : (promo.eligible_promo_product_ids.includes(cartProduct.product.id) || promo.eligible_discount_product_ids.includes(cartProduct.product.id))));
    };
}
