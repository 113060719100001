import React, { useState } from "react";
import UrlUtil from "@onnit-js/ui/utils//UrlUtil";
import Button, { ButtonProps } from "@onnit-js/ui/components/button/Button";
import Product from "@onnit-js/ui/@types/interfaces/product/Product";

interface CartRecommendationItemCtaProps {
    product: Product;
    onClickAdd?: (productId: number) => Promise<any>;
    canAddToBag: boolean;
}

const CartRecommendationItemCta: React.FC<React.PropsWithChildren<CartRecommendationItemCtaProps>> = ({ product, onClickAdd, canAddToBag, ...rest }) => {
    const [isAddingToBag, setIsAddingToBag] = useState<boolean>(false);
    const { product_id, is_apparel } = product;
    const url = UrlUtil.getProductUrl(product);

    const defaultProps: ButtonProps = {
        display: "block",
        width: "80%",
        size: "small",
        margin: "0 auto",
        mb: 2,
    };

    const addProduct = async () => {
        setIsAddingToBag(true);
        await onClickAdd?.(product_id);
    };

    if (is_apparel && url) {
        return <Button el="a" href={url} {...defaultProps} {...rest}>View Sizes</Button>;
    }

    return (
        <Button
            {...defaultProps}
            disabled={canAddToBag}
            onClick={addProduct}
        >
            {isAddingToBag ? "Adding..." : "Add to Bag"}
        </Button>
    );
};

export default CartRecommendationItemCta;
