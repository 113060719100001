import React from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import styled from "styled-components";

const Container = styled(Box as any)`
    @media print {
        -webkit-print-color-adjust: exact !important;
    }
`;

const Bubble: React.FC<React.PropsWithChildren<BoxProps>> = (props) => (
    <Container
        bg="grays.1"
        borderRadius="16px"
        display="inline-flex"
        p="5px 8px 3px 8px"
        alignItems="flex-start"
        {...props}
    />
);

export default Bubble;
