enum ProductTypeIdEnum {
    BASIC_PRODUCT = 1,
    PACKAGE = 2,
    CERTIFICATION = 3,
    APPAREL_CONTAINER = 4,
    APPAREL_VARIANT = 5,
    APPAREL_VARIANT_SIZE = 6,
    DIGITAL = 7,
    GIFT_CARD = 8,
    BOOK = 9,
}

export default ProductTypeIdEnum;
