import { AxiosResponse } from "axios";
import AbstractCartClient from "./AbstractCartClient";
import PaymentGateways from "../../interfaces/payment/PaymentGateways";

export default class CartPaymentGatewayClient extends AbstractCartClient {
    async getPaymentGateways(cartUuid: string): Promise<AxiosResponse<PaymentGateways>> {
        const url = `${cartUuid}/payment-gateways`;

        return this.axios.get(url, {
            // Include authentication token cookie if present, so the API will generate a Braintree client token associated with the customer.
            withCredentials: true,
        });
    }
}
