import React, { ChangeEvent } from "react";
import SelectField from "@onnit-js/ui/components/form/selectfield/SelectField";
import { FreeGiftApparelVariantSize } from "../../../../../interfaces/cart/FreeGift";
import ApparelOption from "./ApparelOption";
import ProductUtil from "../../../../../utils/ProductUtil";

interface Props {
    sizes: FreeGiftApparelVariantSize[];
    selectedSize: FreeGiftApparelVariantSize | null;
    onChange: (size?: FreeGiftApparelVariantSize) => void;
}

const ApparelVariantSizeSelectBox = ({ sizes, selectedSize, ...props }: Props) => {
    const value = selectedSize
        ? selectedSize.id.toString()
        : "";

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const size = sizes.find((size) => size.id.toString() === event.target.value);

        props.onChange(size);
    };

    return (
        <SelectField value={value} onChange={onChange} disabled={!sizes.length} width="100%">
            <option value="">Select size</option>
            {ProductUtil.sortApparelVariantSizes(sizes).map((size) => (
                <ApparelOption
                    key={size.id}
                    product={size}
                />
            ))}
        </SelectField>
    );
};

export default ApparelVariantSizeSelectBox;
