import CartMessage from "./cart/CartMessage";

export enum BootstrapStatus {
    "IDLE" = "IDLE",
    "LOADING" = "LOADING",
    "LOADED" = "LOADED"
}

export enum LoadingKey {
    bootstrap = "bootstrap",
    braintree = "braintree",
    cart = "cart",
    customerPaymentMethods = "customerPaymentMethods",
    other = "other",
    paymentGateways = "paymentGateways",
    shipping = "shipping",
}

export default interface AppState {
    isLoading: boolean;
    loading: {
        [k in LoadingKey]: boolean;
    };
    bootstrapStatus: BootstrapStatus;
    messages: CartMessage[];
}
