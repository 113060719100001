import React from "react";
import Cart from "../../../../interfaces/cart/Cart";
import CartProductImmutable from "./CartProductImmutable";
import CartProductUtil from "../../../../utils/CartProductUtil";
import SubscriptionSwitchBanner from "./subscription/SubscriptionSwitchBanner";

interface Props {
    cart: Cart;
    showProductWarningSymbols: boolean;
    showSubscriptionSwitch: boolean;
}

const CartProductListImmutable = ({ cart, showProductWarningSymbols, showSubscriptionSwitch }: Props) => (
    <div>
        {showSubscriptionSwitch && (
            <SubscriptionSwitchBanner cart={cart} canDisable={false} />
        )}
        {cart.products.map((cartProduct) => (
            <CartProductImmutable
                key={cartProduct.product.id}
                cart={cart}
                cartProduct={cartProduct}
                showProductWarningSymbols={showProductWarningSymbols}
                promoDiscount={CartProductUtil.getPromoDiscount(cart, cartProduct)}
            />
        ))}
    </div>
);

export default CartProductListImmutable;
