import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AddressRecord from "@onnit-js/ui/@types/interfaces/address/AddressRecord";
import CreateUpdateAddressCommand from "@onnit-js/ui/@types/interfaces/address/CreateUpdateAddressCommand";
import CreateUpdateAddressResponse from "@onnit-js/ui/@types/interfaces/address/CreateUpdateAddressResponse";
import AddressClient from "../clients/AddressClient";
import ThunkAction from "../interfaces/ThunkAction";
import ThunkDispatch from "../interfaces/ThunkDispatch";
import HttpUtil from "../utils/HttpUtil";

const initialState: AddressRecord[] = [];

const addressSlice = createSlice({
    name: "addresses",
    initialState,
    reducers: {
        setAddresses(state, action: PayloadAction<AddressRecord[]>) {
            return action.payload;
        },
        addAddress(state, action: PayloadAction<AddressRecord>) {
            state.push(action.payload);
        },
        editAddress(state, action: PayloadAction<AddressRecord>) {
            state = [
                ...state.filter((addr: AddressRecord) => addr.id !== action.payload.id),
                action.payload,
            ];
        },
    }
});

export const {
    setAddresses,
    addAddress,
    editAddress
} = addressSlice.actions;

export default addressSlice;

// ------------------------- [ Thunks ] -------------------------
const addressClient = new AddressClient();

export const loadAddresses = (customerId:number): ThunkAction<Promise<void>> => async (dispatch: ThunkDispatch) => {
    try {
        const response = await addressClient.listAddresses(customerId);
        dispatch(setAddresses(response.data));
        console.debug("Successfully loaded customer addresses, if any.");
     } catch (error: any) {
        const errorMessage = `Failed to load addresses for customer ID ${customerId}.`;
        HttpUtil.logErroneousRequest(errorMessage, error);
    }
};

export const createAddress = (config: CreateUpdateAddressCommand): ThunkAction<Promise<CreateUpdateAddressResponse>> => async (dispatch: ThunkDispatch) => {
    try {
        const response = await addressClient.createAddress(config);
        dispatch(addAddress(response.data));

        return response.data;
     } catch (error: any) {
        HttpUtil.logErroneousRequest("Failed to create address.", error);

        throw error;
    }
};

export const updateAddress = (address: AddressRecord): ThunkAction<Promise<CreateUpdateAddressResponse>> => async (dispatch: ThunkDispatch) => {
    try {
        const response = await addressClient.updateAddress(address);
        dispatch(editAddress(response.data));

        return response.data;
     } catch (error: any) {
        HttpUtil.logErroneousRequest("Failed to update address.", error);

        throw error;
    }
};
