import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ThunkAction from "../interfaces/ThunkAction";
import LoginBootstrap from "../interfaces/auth/LoginBootstrap";
import LoginConfig from "../interfaces/auth/LoginConfig";
import LoginResponse from "../interfaces/auth/LoginResponse";
import HttpUtil from "../utils/HttpUtil";
import ErrorMessageEnum from "../enums/ErrorMessageEnum";
import AuthClient from "../clients/AuthClient";
import AuthState from "../interfaces/auth/AuthState";
import ApiTokenClient from "../clients/ApiTokenClient";

const initialState: AuthState = {
    csrf: null,
    isCaptchaRequired: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLoginBootstrap(state, action: PayloadAction<LoginBootstrap | LoginResponse>) {
            return {
                csrf: action.payload.csrf,
                isCaptchaRequired: action.payload.captcha
            };
        }
    }
});

export const { setLoginBootstrap } = authSlice.actions;

export default authSlice;

// ------------------------- [ Thunks ] -------------------------

const authClient = new AuthClient();
const tokenClient = new ApiTokenClient();

export const loadLoginBootstrap = (): ThunkAction<Promise<LoginBootstrap>> => (
    async (dispatch) => {
        const response = await authClient.getBootstrap();
        const { data } = response;

        dispatch(setLoginBootstrap(data));

        return data;
    }
);

export const login = (login: LoginConfig): ThunkAction<Promise<LoginResponse>> => (
    async (dispatch) => {
        const response = await authClient.login(login).catch((error) => {
            HttpUtil.logErroneousRequest("Failed to login due to unexpected error.", error);
            throw new Error(ErrorMessageEnum.GENERIC);
        });

        const { data } = response;

        // The API should _always_ return a new login bootstrap. CSRF tokens are only valid once, and the CAPTCHA requirement can change between requests.
        if (data.csrf && typeof data.captcha === "boolean") {
            dispatch(setLoginBootstrap(data));
        }
        if (!data.success) {
            console.error("Login returned unsuccessful response.", data);
            throw new Error(data.error ?? ErrorMessageEnum.GENERIC);
        }

        // API auth: sending the token, obtained during login, to an API endpoint that will check it and send back a cookie with the token
        await tokenClient.getTokenDetails(data.token).catch((error) => {
            HttpUtil.logErroneousRequest("Failed to login to API due to unexpected error.", error);
            throw new Error(ErrorMessageEnum.GENERIC);
        });

        return data;
    }
);
