import React, { useState } from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import FreeGift, { FreeGiftApparelContainer, FreeGiftApparelVariant, FreeGiftApparelVariantSize } from "../../../../../interfaces/cart/FreeGift";
import ProductTypeIdEnum from "../../../../../enums/ProductTypeIdEnum";
import ProductUtil from "../../../../../utils/ProductUtil";
import ApparelVariantSelectBox from "./ApparelVariantSelectBox";
import ApparelVariantSizeSelectBox from "./ApparelVariantSizeSelectBox";

interface Props extends BoxProps {
    freeGift: FreeGift;
    setImageUrl: (imageUrl: string) => void;
    selectedSize: FreeGiftApparelVariantSize | null;
    setSelectedSize: (size: FreeGiftApparelVariantSize | null) => void;
}

const ApparelSelectBoxContainer = ({ freeGift: { product }, setImageUrl, setSelectedSize, selectedSize, ...rest }: Props) => {
    const [selectedVariant, setSelectedVariant] = useState<FreeGiftApparelVariant | null>(null);

    if (!product) {
        console.error("Apparel Free gift unexpectedly contains no associated apparel product");
        return null;
    }

    if (!ProductUtil.isApparelParentTypeId(product.typeId)) {
        console.error("Unexpected apparel parent type ID '%d' passed as prop to 'ApparelSelectBoxContainer'.", product.typeId);
        return null;
    }

    let sizes: FreeGiftApparelVariantSize[] = [];
    if (product.typeId === ProductTypeIdEnum.APPAREL_VARIANT) {
        sizes = (product as FreeGiftApparelVariant).sizes;
    } else if (selectedVariant) {
        sizes = selectedVariant.sizes;
    }

    return (
        <Box {...rest}>
            {product.typeId === ProductTypeIdEnum.APPAREL_CONTAINER && (
                <ApparelVariantSelectBox
                    variants={(product as FreeGiftApparelContainer).variants}
                    selectedVariant={selectedVariant}
                    onChange={(variant) => {
                        // An image should only change with a variant change.
                        if (variant) {
                            setImageUrl(variant.authorityImageUrl);
                        }

                        setSelectedVariant(variant || null);
                        setSelectedSize(null); // In case previously set.
                    }}
                />
            )}
            <ApparelVariantSizeSelectBox
                sizes={sizes}
                selectedSize={selectedSize}
                onChange={(size) => setSelectedSize(size || null)}
            />
        </Box>
    );
};

export default ApparelSelectBoxContainer;
