import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import CartTotal from "./CartTotal";
import CartTotalLabelEnum from "../../../../enums/CartTotalLabelEnum";
import CouponBubbleList from "../../coupon/CouponBubbleList";
import Cart from "../../../../interfaces/cart/Cart";

interface Props {
    cart: Cart;
    canRemoveCoupons: boolean;
}

const CartTotalList = (props: Props) => {
    const { cart, canRemoveCoupons } = props;
    const { cart_uuid, coupons, totals } = cart;
    const doShowDiscount = !!cart.totals.discount;

    return (
        <Box role="table" aria-label="Order Totals">
            <div role="rowgroup" className="sr-only">
                <div role="row">
                    <span role="columnheader">
                        Item
                    </span>
                    <span role="columnheader">
                        Value
                    </span>
                </div>
            </div>
            <Box borderBottom="1px solid" borderColor="grays.1" py={3} role="rowgroup">
                <CartTotal label={CartTotalLabelEnum.SUBTOTAL} value={totals.msrp} />
                {doShowDiscount && <CartTotal label={CartTotalLabelEnum.DISCOUNT} value={totals.discount} />}
                <CouponBubbleList cartUuid={cart_uuid} coupons={coupons} canRemoveCoupons={canRemoveCoupons} />
                <CartTotal label={CartTotalLabelEnum.SHIPPING} value={totals.shipping} />
                <CartTotal label={CartTotalLabelEnum.TAX} value={totals.tax} />
            </Box>
            <Box py={2} role="rowgroup">
                <CartTotal label={CartTotalLabelEnum.GRAND} value={totals.grand} currency="USD" />
            </Box>
        </Box>
    );
};

export default CartTotalList;
