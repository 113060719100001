import Cart from "../../interfaces/cart/Cart";
import CreateOrderResult from "../../interfaces/order/CreateOrderResult";
import AbstractEvent from "./AbstractEvent";

export default class PostUpsellOrderCreatedEvent extends AbstractEvent {
    static readonly eventName: string = "PostUpsellOrderCreated";

    readonly cart: Cart;

    readonly order: CreateOrderResult;

    constructor(cart: Cart, order: CreateOrderResult) {
        super();
        this.cart = cart;
        this.order = order;
    }

    getName(): string {
        return PostUpsellOrderCreatedEvent.eventName;
    }
}
