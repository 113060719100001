import { AxiosResponse } from "axios";
import AbstractCartClient from "./AbstractCartClient";
import Cart from "../../interfaces/cart/Cart";
import { EnteredCouponConfig } from "../../interfaces/coupon/EnteredCouponConfig";

interface ApplyCouponRequestParams {
    do_calculate_tax: boolean;
    client_country_code: string | null | undefined;
}

export default class CartCouponClient extends AbstractCartClient {
    async applyCoupon(config: EnteredCouponConfig, applyCouponParams: ApplyCouponRequestParams): Promise<AxiosResponse<Cart>> {
        const { code, cart_uuid } = config;
        const url = cart_uuid ? `${cart_uuid}/coupons` : "coupons";

        return this.axios.post(
            url,
            {
                code,
            },
            {
                params: applyCouponParams
            }
        );
    }

    async removeCoupon(cartUuid: string, couponId: number): Promise<AxiosResponse<Cart>> {
        const url = `${cartUuid}/coupons/${couponId}`;

        return this.axios.delete(url, {
            // Include authentication token cookie if present, so the API can associate the cart to the customer.
            withCredentials: true,
        });
    }
}
