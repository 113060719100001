import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PaymentMethod from "@onnit-js/ui/@types/interfaces/payment-method/PaymentMethod";
import PaymentMethodTypeEnum from "@onnit-js/ui/@types/enums/payment-method/PaymentMethodTypeEnum";
import PaymentMethodStatusCodeEnum from "@onnit-js/ui/@types/enums/payment-method/PaymentMethodStatusCodeEnum";
import BraintreeService from "@onnit-js/ui/services//braintree/BraintreeService";
import PaymentState, { PaymentRequestStatus } from "../interfaces/payment/PaymentState";
import PaymentGateways from "../interfaces/payment/PaymentGateways";
import TokenizedPaymentMethod from "../interfaces/payment/TokenizedPaymentMethod";
import CartPaymentGatewayClient from "../clients/cart/CartPaymentGatewayClient";
import ThunkAction from "../interfaces/ThunkAction";
import PaymentMethodClient from "../clients/PaymentMethodClient";
import TokenizedPaymentMethodFactory from "../services/TokenizedPaymentMethodFactory";
import { setLoading } from "./appSlice";
import ErrorMessageEnum from "../enums/ErrorMessageEnum";
import Cart from "../interfaces/cart/Cart";
import PaymentService from "../services/PaymentService";
import PaymentGatewayIdEnum from "../enums/payment/PaymentGatewayIdEnum";
import PaymentMethodInstrumentEnum from "../enums/payment/PaymentMethodInstrumentEnum";
import HttpUtil from "../utils/HttpUtil";
import { LoadingKey } from "../interfaces/AppState";

const initialState: PaymentState = {
    /* -- Gateways --*/
    gatewaysStatus: PaymentRequestStatus.IDLE,
    gateways: [],
    /* -- Payment Methods --*/
    customerMethodsStatus: PaymentRequestStatus.IDLE,
    customerMethods: [],
    tokenizedMethods: null,
    tokenizedMethodSelected: null,
    do_save: null,
    error: "",
};

const setDefaultTokenizedMethodSelected = (state: PaymentState): PaymentState => {
    if (!state.gateways[0]) {
        return state;
    }

    const tokenizedFree: TokenizedPaymentMethod = {
        payment_gateway_id: PaymentGatewayIdEnum.FREE,
        instrument: PaymentMethodInstrumentEnum.FREE,
        instrument_type: PaymentMethodInstrumentEnum.FREE,
    };

    switch (state.gateways[0].payment_gateway_id) {
        case PaymentGatewayIdEnum.FREE:
            state.tokenizedMethods = [tokenizedFree];
            state.tokenizedMethodSelected = tokenizedFree;
            console.debug("Set Free tokenized payment method.");
            return state;
        case PaymentGatewayIdEnum.BRAINTREE:
            if (state.customerMethods.length > 0) {
                const methods = state.customerMethods
                    .filter((method: PaymentMethod) => ![PaymentMethodStatusCodeEnum.EXPIRED, PaymentMethodStatusCodeEnum.REVOKED].includes(method.status_code))
                    .map((method: PaymentMethod) => TokenizedPaymentMethodFactory.fromStoredPaymentMethod(method));
                state.tokenizedMethods = methods;
                state.tokenizedMethodSelected = methods[0] ?? null;
                console.debug("Set customer's default tokenized payment method.");
            }
            return state;
        default:
            console.error("Payment gateway is unrecognized.");
            return state;
    }
};

const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        setGatewaysStatus(state, action: PayloadAction<PaymentRequestStatus>) {
            state.gatewaysStatus = action.payload;
        },
        setPaymentGateways(state, action: PayloadAction<PaymentGateways>) {
            state.gateways = action.payload;
            state.tokenizedMethods = null;
            state.tokenizedMethodSelected = null;
            state.gatewaysStatus = PaymentRequestStatus.SUCCESS;
            state.error = "";
            setDefaultTokenizedMethodSelected(state);
        },
        setCustomerMethodsStatus(state, action: PayloadAction<PaymentRequestStatus>) {
            state.customerMethodsStatus = action.payload;
        },
        setCustomerMethods(state, action: PayloadAction<PaymentMethod[]>) {
            state.customerMethods = action.payload;
            state.customerMethodsStatus = PaymentRequestStatus.SUCCESS;
            setDefaultTokenizedMethodSelected(state);
        },
        setPaymentTokenizedMethods(state, action: PayloadAction<TokenizedPaymentMethod[]>) {
            state.tokenizedMethods = action.payload;
        },
        addPaymentTokenizedMethod(state, action: PayloadAction<TokenizedPaymentMethod>) {
            state.error = "";
            state.tokenizedMethods = state.tokenizedMethods
                ? [...state.tokenizedMethods, action.payload]
                : [action.payload];
            state.tokenizedMethodSelected = action.payload;
        },
        removePaymentTokenizedMethod(state, action: PayloadAction<TokenizedPaymentMethod>) {
            if (state.tokenizedMethods) {
                state.tokenizedMethods = state.tokenizedMethods.filter((method) => (action.payload.nonce && action.payload.nonce !== method.nonce)
                        || (action.payload.method_token && action.payload.method_token !== method.method_token));
            }
        },
        setPaymentTokenizedMethodSelected(state, action: PayloadAction<TokenizedPaymentMethod | null>) {
            state.tokenizedMethodSelected = action.payload;
            if (action.payload) {
                // Do not clear error if payload is null so we can persist the error message
                state.error = "";
            }
        },
        setDeviceData(state, action: PayloadAction<string>) {
            state.device_data = action.payload;
        },
        setDoSave(state, action: PayloadAction<boolean | null>) {
            state.do_save = action.payload;
        },
        setPaymentError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
    }
});

export const {
    setGatewaysStatus,
    setPaymentGateways,
    setCustomerMethodsStatus,
    setCustomerMethods,
    setPaymentTokenizedMethods,
    addPaymentTokenizedMethod,
    removePaymentTokenizedMethod,
    setPaymentTokenizedMethodSelected,
    setDeviceData,
    setDoSave,
    setPaymentError
} = paymentSlice.actions;

export default paymentSlice;

// ------------------------- [ Thunks ] -------------------------

const cartPaymentGatewayClient = new CartPaymentGatewayClient();
const paymentMethodClient = new PaymentMethodClient();

export const loadPaymentGateways = (cartUuid: string): ThunkAction<Promise<void>> => (
    async (dispatch, getState) => {
        // Use state status to guard against multiple fetches which could happen in cartListener.
        if (getState().payment.gatewaysStatus === PaymentRequestStatus.FETCHING) {
            return;
        }
        dispatch(setLoading({ key: LoadingKey.paymentGateways, isLoading: true }));
        dispatch(setGatewaysStatus(PaymentRequestStatus.FETCHING));
        try {
            const response = await cartPaymentGatewayClient.getPaymentGateways(cartUuid);
            console.debug("Successfully loaded payment gateways");
            dispatch(setPaymentGateways(response.data));
         } catch (error: any) {
            dispatch(setGatewaysStatus(PaymentRequestStatus.ERROR));
            HttpUtil.logErroneousRequest("Failed to load payment gateways", error);
        } finally {
            dispatch(setLoading({ key: LoadingKey.paymentGateways, isLoading: false }));
        }
    }
);

export const loadCustomerPaymentMethods = (customerId: number): ThunkAction<Promise<void>> => (
    async (dispatch, getState) => {
        if (getState().payment.customerMethodsStatus === PaymentRequestStatus.FETCHING) {
            return;
        }
        dispatch(setLoading({ key: LoadingKey.customerPaymentMethods, isLoading: true }));
        dispatch(setCustomerMethodsStatus(PaymentRequestStatus.FETCHING));
        try {
            const response = await paymentMethodClient.listMethods(customerId);
            // Exclude Apple Pay and duplicates.  Stored Apple Pay payments cannot be used per Braintree tech support team.
            const methods = response.data.filter((method) => !method.is_duplicate && method.method_type !== PaymentMethodTypeEnum.APPLE_PAY);

            dispatch(setCustomerMethods(methods));
            console.debug("Fetched customer's stored payment methods, if any.");
         } catch (error: any) {
            dispatch(setCustomerMethodsStatus(PaymentRequestStatus.ERROR));
            HttpUtil.logErroneousRequest("Failed to fetched customer's stored payment methods", error);
        } finally {
            dispatch(setLoading({ key: LoadingKey.customerPaymentMethods, isLoading: false }));
        }
    }
);

export const addPaymentMethod = (btService: BraintreeService): ThunkAction<Promise<PaymentState | null | undefined>> => (
    async (dispatch, getState) => {
        const { tokenizedMethodSelected } = getState().payment;
        const { cart } = getState();
        const gateway = getState().payment.gateways[0];

        dispatch(setLoading({ key: LoadingKey.other, isLoading: true }));
        dispatch(setPaymentError(""));

        try {
            if (tokenizedMethodSelected) {
                return getState().payment;
            }

            // Tokenize the new method in HostedFields.
            // Type assertions because this.validate takes care of it.
            const btTokenizedMethod = await (btService).tokenizeHostedFields();
            const tokenizedMethod = TokenizedPaymentMethodFactory.fromBtTokenizedMethod(
                btTokenizedMethod,
                PaymentMethodTypeEnum.CREDIT_CARD,
            );

            // Guest checkouts won't have a tokenizedPaymentMethod until they click "Pay now".
            // Then this method runs so we need to wait until after btService.tokenizeHostedFields()
            // runs before validating.
            PaymentService.validate(cart as Cart, gateway, btService, tokenizedMethodSelected ?? tokenizedMethod);

            (btService).clearHostedFields()
                .catch((error) => console.error("Braintree: Failed to clear HostedFields.", error));

            dispatch(addPaymentTokenizedMethod(tokenizedMethod));

            return getState().payment;
         } catch (error: any) {
            // ValidationError can have a userMessage property set.
            const message = error.userMessage || ErrorMessageEnum.GENERIC;
            dispatch(setPaymentError(message));
            console.error(message);
            return null;
        } finally {
            dispatch(setLoading({ key: LoadingKey.other, isLoading: false }));
        }
    }
);
