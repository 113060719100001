import config from "../config";
import ProductTypeIdEnum from "../enums/ProductTypeIdEnum";
import { FreeGiftApparelVariantSize } from "../interfaces/cart/FreeGift";
import CartProduct from "../interfaces/cart/CartProduct";

const APPAREL_PARENT_TYPE_IDS = [
    ProductTypeIdEnum.APPAREL_CONTAINER,
    ProductTypeIdEnum.APPAREL_VARIANT,
];

export default class ProductUtil {
    static makeProductUrl = (slug: string | null): string | undefined => (
        slug ? `${config.ALLIT_URL}/${slug}` : undefined
    );

    static isApparelParentTypeId = (typeId: number | undefined): boolean => (
        typeId ? APPAREL_PARENT_TYPE_IDS.includes(typeId) : false
    );

    static isDigitalProduct = (cartProduct: CartProduct) => (
        cartProduct.product.typeId === ProductTypeIdEnum.DIGITAL
    );

    static sortApparelVariantSizes = (variants: FreeGiftApparelVariantSize[]): FreeGiftApparelVariantSize[] => {
        const shallowCopy = [...variants];
        const getSizeSortOrder = (variant: FreeGiftApparelVariantSize): number => {
            switch (variant.size) {
                case "XXSMALL":
                    return 1;
                case "XSMALL":
                    return 2;
                case "SMALL":
                    return 3;
                case "S/M":
                    return 4;
                case "MEDIUM":
                    return 5;
                case "LARGE":
                    return 6;
                case "L/XL":
                    return 7;
                case "XLARGE":
                    return 8;
                case "2XLARGE":
                    return 9;
                case "3XLARGE":
                    return 10;
                case "One Size":
                    return 11;
                default:
                    return -1;
            }
        };

        shallowCopy.sort((first, second) => getSizeSortOrder(first) - getSizeSortOrder(second));

        return shallowCopy;
    };
}
