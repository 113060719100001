import CartProduct from "../../interfaces/cart/CartProduct";
import Cart from "../../interfaces/cart/Cart";
import AbstractEvent from "./AbstractEvent";

export default class CartProductRemovedEvent extends AbstractEvent {
    static readonly eventName: string = "CartProductRemoved";

    readonly cartProductRemoved: CartProduct;

    readonly cart: Cart;

    constructor(cartProduct: CartProduct, cart: Cart) {
        super();
        this.cartProductRemoved = cartProduct;
        this.cart = cart;
    }

    getName(): string {
        return CartProductRemovedEvent.eventName;
    }
}
