import AbstractEvent from "./AbstractEvent";

export default class SurveyCompletedEvent extends AbstractEvent {
    static readonly eventName: string = "SurveyCompleted";

    readonly answer: string;
    readonly listName?: string;

    constructor(answer: string, listName?: string) {
        super();
        this.answer = answer;
        this.listName = listName;
    }

    getName(): string {
        return SurveyCompletedEvent.eventName;
    }
}
