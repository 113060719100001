import ProductData from "@onnit-js/ui/@types/interfaces/google-tag-manager/ProductData";
import { CartProducts } from "../../../interfaces/cart/CartProduct";
import {
    CartViewedPayload,
    CheckoutData,
    CheckoutStepOptionPayload,
    CheckoutStepPayload,
    CouponActionPayload,
    SurveyCompletedPayload,
    EnhancedEcommerceEventNameEnum,
    EnhancedEcommercePayload,
    ProductImpressionPayload,
    ProductsAddedPayload,
    ProductsRemovedPayload,
    PurchasePayload,
    StepNumberEnum,
} from "./types";
import Cart from "../../../interfaces/cart/Cart";
import CreateOrderResult from "../../../interfaces/order/CreateOrderResult";
import { ImpressionProducts } from "../../../events/events/ProductImpressionEvent";
import Product from "../../../interfaces/cart/Product";
import { UpsellProduct } from "../../../interfaces/cart/CartUpsell";

/**
 * See: https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
 *      https://developers.google.com/tag-manager/enhanced-ecommerce
 */
export default class EnhancedEcommercePayloadFactory {
    static makeProductImpression(products: ImpressionProducts, listName: string): EnhancedEcommercePayload<ProductImpressionPayload> {
        const impressions = products.map((product, index) => ({
            ...this.makeBaseProduct(product),
            list: listName,
            position: index + 1,
        }));

        return {
            event: EnhancedEcommerceEventNameEnum.PRODUCT_IMPRESSION,
            ecommerce: {
                impressions, // UA
                items: impressions, // GA4
            },
        };
    }

    static makeCartViewed(
        email: string | null,
        emailHash: string | null,
        cartProducts: CartProducts,
    ): EnhancedEcommercePayload<CartViewedPayload> {
        const products = this.makeProductsFromCart(cartProducts);
        return {
            event: EnhancedEcommerceEventNameEnum.CART_VIEWED,
            ecommerce: {
                currencyCode: "USD", // UA
                currency: "USD", // GA4
                email,
                emailHash,
                cart_view: {
                    products, // UA
                    items: products, // GA4
                },
            },
        };
    }

    static makeSurveyCompletedData(
        answer: string,
        listName?: string,
    ): EnhancedEcommercePayload<SurveyCompletedPayload> {
        return {
            event: EnhancedEcommerceEventNameEnum.SURVEY_COMPLETED,
            ecommerce: {
                survey: {
                    actionField: {
                        list: listName,
                    },
                    answer
                },
            },
        };
    }

    static makeCouponEntered(coupon: string): EnhancedEcommercePayload<CouponActionPayload> {
        return {
            event: EnhancedEcommerceEventNameEnum.COUPON_ENTERED,
            ecommerce: {
                coupon,
            },
        };
    }

    static makeCouponApplied(coupon: string): EnhancedEcommercePayload<CouponActionPayload> {
        return {
            event: EnhancedEcommerceEventNameEnum.COUPON_APPLIED,
            ecommerce: {
                coupon,
            },
        };
    }

    static makeCouponDenied(coupon: string): EnhancedEcommercePayload<CouponActionPayload> {
        return {
            event: EnhancedEcommerceEventNameEnum.COUPON_DENIED,
            ecommerce: {
                coupon,
            },
        };
    }

    static makeCouponRemoved(coupon: string): EnhancedEcommercePayload<CouponActionPayload> {
        return {
            event: EnhancedEcommerceEventNameEnum.COUPON_REMOVED,
            ecommerce: {
                coupon,
            },
        };
    }

    // eslint-disable-next-line max-params
    static makeProductsAdded(
        email: string | null,
        emailHash: string | null,
        addedCartProducts: CartProducts,
        allCartProducts: CartProducts,
        eventName: EnhancedEcommerceEventNameEnum,
        listName?: string,
    ): EnhancedEcommercePayload<ProductsAddedPayload> {
        const products = this.makeProductsFromCart(addedCartProducts);
        const allProducts = this.makeProductsFromCart(allCartProducts);

        return {
            event: eventName,
            ecommerce: {
                currencyCode: "USD", // UA
                currency: "USD", // GA4
                email,
                emailHash,
                add: {
                    actionField: {
                        list: listName,
                    },
                    products, // UA
                    items: products, // GA4
                    allProducts,
                },
            },
        };
    }

    static makeProductsRemoved(
        email: string | null,
        emailHash: string | null,
        removedCartProducts: CartProducts,
        allCartProducts: CartProducts,
        eventName: EnhancedEcommerceEventNameEnum
    ): EnhancedEcommercePayload<ProductsRemovedPayload> {
        const products = this.makeProductsFromCart(removedCartProducts);
        const allProducts = this.makeProductsFromCart(allCartProducts);

        return {
            event: eventName,
            ecommerce: {
                currencyCode: "USD", // UA
                currency: "USD", // GA4
                email,
                emailHash,
                remove: {
                    products, // UA
                    items: products, // GA4
                    allProducts,
                },
            },
        };
    }

    static makeCheckoutStep(
        cart: Cart,
        stepNumber: StepNumberEnum,
        option?: string,
    ): EnhancedEcommercePayload<CheckoutStepPayload> {
        return {
            event: EnhancedEcommerceEventNameEnum.CHECKOUT_STEP,
            ecommerce: {
                currencyCode: "USD", // UA
                currency: "USD", // GA4
                email: cart.customer_email,
                emailHash: cart.customer_email_hash,
                checkout: this.makeCheckoutData(cart, stepNumber, option),
            },
        };
    }

    static makeCheckoutStepOption(
        cart: Cart,
        stepNumber: StepNumberEnum,
        option: string,
    ): EnhancedEcommercePayload<CheckoutStepOptionPayload> {
        return {
            event: EnhancedEcommerceEventNameEnum.CHECKOUT_STEP_OPTION,
            ecommerce: {
                currencyCode: "USD", // UA
                currency: "USD", // GA4
                email: cart.customer_email,
                emailHash: cart.customer_email_hash,
                checkout_option: this.makeCheckoutData(cart, stepNumber, option),
            },
        };
    }

    static makePurchase(cart: Cart, order: CreateOrderResult, eventName: EnhancedEcommerceEventNameEnum): EnhancedEcommercePayload<PurchasePayload> {
        const { customer_email, customer_email_hash, coupons, shipping_address, totals: { grand, tax, shipping, discount } } = cart;
        const products = this.makeProductsFromCart(cart.products);
        // GA only accepts one coupon.
        const couponCode = coupons.length ? coupons[0].code : undefined;
        // Exclude deprecated group names that belong to every customer.
        const customerGroupNames = order.customer.group_names?.filter((name) => !["Guest", "Retail"].includes(name));
        const shippingMethodNames = cart.shipping_quotes_selected.map(
            ({ shipping_method }) => `${shipping_method.courier_name} ${shipping_method.service_name}`,
        );

        return {
            event: eventName,
            ecommerce: {
                currencyCode: "USD", // UA
                currency: "USD", // GA4
                email: customer_email || "",
                emailHash: customer_email_hash || "",
                purchase: {
                    actionField: {
                        id: order.order_id, // UA
                        transaction_id: order.order_id, // GA4
                        affiliation: order.affiliate_tracking_code,
                        revenue: grand, // UA
                        value: grand, // GA4
                        tax: tax ?? undefined,
                        shipping: shipping ?? undefined,
                        discountAmount: discount,
                        coupon: couponCode,
                        paymentMethod: order.payment_method_type,
                        customerGroup: customerGroupNames?.length ? customerGroupNames.join(", ") : null,
                        isCustomerFirstOrder: order.is_customer_first_order,
                        customer: {
                            id: order.customer.customer_id,
                            firstName: order.customer.first_name,
                            lastName: order.customer.last_name,
                        },
                        shippingMethod: shippingMethodNames.join(", "),
                        quantitySum: cart.quantity_sum,
                        shippingAddress: {
                            city: shipping_address?.city ?? null,
                            state: shipping_address?.state ?? null,
                            countryCode: shipping_address?.country_code ?? null,
                            countryName: shipping_address?.country_name ?? null,
                        },
                        createdAt: order.created_at,
                    },
                    products, // UA
                    items: products, // GA4
                },
            },
        };
    }

    // ------------------------- [ Helpers ] -------------------------

    private static makeCheckoutData(cart: Cart, stepNumber: StepNumberEnum, option?: string): CheckoutData {
        const { coupons, totals: { grand, tax, shipping, discount } } = cart;
        // GA only accepts one coupon.
        const couponCode = coupons.length ? coupons[0].code : undefined;
        const products = this.makeProductsFromCart(cart.products);
        const shippingMethodNames = cart.shipping_quotes_selected.map(
            ({ shipping_method }) => `${shipping_method.courier_name} ${shipping_method.service_name}`,
        );

        return {
            actionField: {
                step: stepNumber,
                option,
                revenue: grand,
                value: grand, // NOTE: Updated for GA4; was msrp for UA
                tax: tax ?? undefined,
                quantitySum: cart.quantity_sum,
                shipping: shipping ?? undefined,
                discountAmount: discount,
                coupon: couponCode,
                shippingMethod: shippingMethodNames.join(", "),
                shipping_tier: shippingMethodNames.join(", "), // GA4
            },
            products, // UA
            items: products, // GA4
        };
    }

    private static makeProductsFromCart(cartProducts: CartProducts): ProductData[] {
        return cartProducts.map((cartProduct, index) => {
            const position = index + 1;
            if (position < 1) {
                throw new Error("Position must be at least 1.");
            }

            const { coupon_codes_applied, product, subscription_interval, is_upsell } = cartProduct;
            const coupon = coupon_codes_applied.length ? coupon_codes_applied[0] : undefined; // GA only accepts one.
            const { totals } = cartProduct;
            return {
                ...this.makeBaseProduct(product),
                msrp: totals.msrp, // Confusing name, but here for backwards compatibility.
                msrpTotal: totals.msrp,
                priceTotal: totals.price,
                adjustedPriceTotal: totals.adjusted_price,
                discountTotal: totals.discount,
                discount: totals.discount, // GA4
                quantity: cartProduct.quantity,
                coupon,
                position,
                dimension1: subscription_interval || 0,
                dimension2: is_upsell,
                dimension20: subscription_interval !== null,
            };
        });
    }

    private static makeBaseProduct(product: Product | UpsellProduct): ProductData {
        return {
            // UA parameters
            id: product.groupId,
            name: product.groupName,
            variant: product.name,
            category: product.onnitCategory,
            brand: product.brand ?? undefined,

            // GA4 parameters
            item_id: product.groupId,
            item_name: product.groupName,
            item_variant: product.name,
            price: product.price,
            item_category: product.onnitCategory,
            item_brand: product.brand ?? undefined,

            // Used by other
            productId: product.id,
            sku: product.sku,
            imageUrl: product.authorityImageUrl,
            reviewConfigId: "productReviewConfigId" in product
                ? product.productReviewConfigId
                : null,
        };
    }
}
