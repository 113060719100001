import React, { FC, useState } from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Product from "@onnit-js/ui/@types/interfaces/product/Product";
import UrlUtil from "@onnit-js/ui/utils//UrlUtil";
import ProductImage from "@onnit-js/ui/components/image/ProductImage";
import { connect, ConnectedProps } from "react-redux";
import RecommendationItemProductName from "@onnit-js/ui/components/amazonPersonalize/RecommendationItemProductName";
import RecommendationItemPrice from "@onnit-js/ui/components/amazonPersonalize/RecommendationItemPrice";
import CartRecommendationCta from "./CartRecommendationItemCta";
import { addProduct } from "../../../../../slices/cartSlice";
import { setIsLoading } from "../../../../../slices/appSlice";
import CartBrowserStorageService from "../../../../../services/CartBrowserStorageService";

interface FlyoutRecommendationItemProps extends BoxProps, ReduxProps {
    item: Product,
    onClick: (event: any) => void;
}

const CartRecommendationItem: FC<React.PropsWithChildren<FlyoutRecommendationItemProps>> = ({ item, onClick, ...props }) => {
    const { product_name, authority_image_url } = item;
    const [isAddToBagDisabled, setIsAddToBagDisabled] = useState<boolean>(false);
    const url = UrlUtil.getProductUrl(item);
    const { setIsLoading, addProduct } = props;

    const handleOnClickAdd = async (productId: number) => {
        setIsLoading(true);
        setIsAddToBagDisabled(true);
        const cartUuid = CartBrowserStorageService.getCartUuid();

        await addProduct({
            product_id: productId,
            quantity: 1,
            subscription_interval: null,
            product_stock_priority_token: null,
        }, cartUuid, "Cart Recommended Products");
        setIsLoading(false);
    };

    return (
        <Box maxWidth="180px">
            <Box
                display="flex"
                flexWrap="wrap"
                height="100%"
            >
                <ProductImage
                    productUrl={url}
                    imageUrl={authority_image_url}
                    productName={product_name}
                    width="60px"
                    onClick={onClick}
                />
                <Box flex="1 1 30%">
                    <RecommendationItemProductName item={item} fontSize={1} style={{ textDecoration: "none" }} />
                    <RecommendationItemPrice item={item} fontSize={1} />
                </Box>
                <Box flex="1" display="flex" flexDirection="column" justifyContent="end" mt={2}>
                    <CartRecommendationCta
                        canAddToBag={isAddToBagDisabled}
                        onClickAdd={handleOnClickAdd}
                        product={item}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = {
    addProduct,
    setIsLoading,
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default connect(null, mapDispatchToProps)(CartRecommendationItem);
