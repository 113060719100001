import React from "react";
import { TypographyProps } from "@techstack/styled-system";
import CartMessage from "../checkout/CartMessage";
import CartMessageLevelEnum from "../../enums/CartMessageLevelEnum";
import ErrorMessageEnum from "../../enums/ErrorMessageEnum";

interface Props extends TypographyProps {
    error?: string;
}

const ErrorBox = ({ error = ErrorMessageEnum.GENERIC }: Props) => (
    <CartMessage level={CartMessageLevelEnum.ERROR} messageHtml={error} />
);

export default ErrorBox;
