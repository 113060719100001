import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text/Text";
import { MdErrorOutline, MdInfoOutline } from "react-icons/md";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FontWeightNames } from "@onnit-js/ui/components/themes/interfaces";
import CartMessageLevelEnum from "../../enums/CartMessageLevelEnum";

interface Props {
    level: CartMessageLevelEnum;
    messageHtml: string;
    color?: string|null;
    fontWeight?: FontWeightNames;
}

const DEFAULT_COLOR = "grays.5";

const LEVEL_TO_COLOR = new Map([
    [CartMessageLevelEnum.INFO, DEFAULT_COLOR],
    [CartMessageLevelEnum.ERROR, "gameRed"],
]);

const LEVEL_TO_ICON = new Map([
    [CartMessageLevelEnum.INFO, MdInfoOutline],
    [CartMessageLevelEnum.ERROR, MdErrorOutline],
]);

const CartMessage = ({ level, messageHtml, color, fontWeight = "regular" }: Props) => {
    const finalColor = LEVEL_TO_COLOR.get(level) ?? color ?? DEFAULT_COLOR;
    const icon = LEVEL_TO_ICON.get(level);

    return (
        <Box display="flex" py={2}>
            {icon && (
                <Box mr={1}>
                    <Icon size={20} color={finalColor} icon={icon} transform="translateY(-3px)" />
                </Box>
            )}
            <Text
                role="alert"
                color={finalColor}
                fontSize={1}
                textAlign="left"
                fontWeight={fontWeight}
                dangerouslySetInnerHTML={{ __html: messageHtml }}
            />
        </Box>
    );
};

export default CartMessage;
