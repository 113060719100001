import { AxiosResponse } from "axios";
import AbstractClient from "./AbstractClient";
import config from "../config";

export default class ApiTokenClient extends AbstractClient {
    protected getBaseUrl(): string {
        // Param has to be here, otherwise the request will go to /token/?param=val which will cause CORS problem
        return `${config.API_URL}/tokens?set-cookie=true`;
    }

    async getTokenDetails(accessToken: string): Promise<AxiosResponse<void>> {
        return this.axios.get("", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    }

    protected withCredentials(): boolean {
        return true;
    }
}
