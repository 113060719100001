import React, { ChangeEvent } from "react";
import SelectField from "@onnit-js/ui/components/form/selectfield/SelectField";
import { FreeGiftApparelVariant } from "../../../../../interfaces/cart/FreeGift";
import ApparelOption from "./ApparelOption";

interface Props {
    variants: FreeGiftApparelVariant[];
    selectedVariant: FreeGiftApparelVariant | null;
    onChange: (variant?: FreeGiftApparelVariant) => void;
}

const ApparelVariantSelectBox = ({ variants, selectedVariant, ...props }: Props) => {
    const value = selectedVariant
        ? selectedVariant.id.toString()
        : "";

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const variant = variants.find((variant) => variant.id.toString() === event.target.value);

        props.onChange(variant);
    };

    return (
        <SelectField value={value} onChange={onChange} width="100%">
            <option value="">Select color</option>
            {variants.map((variant) => (
                <ApparelOption
                    key={variant.id}
                    product={variant}
                />
            ))}
        </SelectField>
    );
};

export default ApparelVariantSelectBox;
